import React, { useState, useEffect, useContext } from 'react';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { translation } from './../../utils';
//import { fetchTC } from './../../utils/tcUtility';
import { SurveyCard } from './SurveyCard';

import { theme } from './../../theme';

import { SdmTypography, SdmIconAndTextButton } from './../../components';

import { useWebinarInteraction } from './../../utils/webinarInteractionHelper';

import { AuthContext, LoaderContext } from './../../contexts/index';

const Survey = ({ onSend, sessionId /*, eventId */ }) => {
  //State
  const [data, setData] = useState();
  const [selected, setSelected] = useState();

  //Contexts
  const { user } = useContext(AuthContext);
  const { setLoaderActive } = useContext(LoaderContext);

  //Effects
  useEffect(() => {
    //if (eventId)
    fetchPoll();
  }, []);

  // Loader Functions
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);

  //Hooks
  const intl = useIntl();
  const i18n = translation.webinar.surveyDialogContent(intl);
  const { getPoll, postPoll } = useWebinarInteraction({
    showLoader,
    hideLoader,
  });

  //Functions
  const onSendAnswer = async () => {
    const idPoll = data?.idPoll;
    const idAnswer = selected?.id;

    const res = await postPoll({ user, sessionId, idPoll, idAnswer });

    const nextData = res?.[0];

    setSelected(null);
    setData(nextData);

    if (!nextData.idPoll) onSend && onSend();
  };

  const fetchPoll = async () => {
    const res = await getPoll({ user, sessionId });

    const nextData = res?.[0];

    setData(nextData);
  };

  return (
    <Grid
      container
      style={{
        color: theme.colors.greyScale.white,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        textAlign: 'center',
      }}
    >
      <Grid item xs={12}>
        <SdmTypography
          style={{ font: 'normal normal 300 54px/36px Roboto' }}
          textAlign="center"
        >
          {data?.question}
        </SdmTypography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '2%' }}>
        {data?.answers ? (
          <Grid container style={{ width: '50%', margin: 'auto' }} spacing={1}>
            {data?.answers?.map((i) => (
              <Grid key={i.id} item xs={6}>
                <SurveyCard
                  item={i}
                  onSelect={setSelected}
                  selected={selected}
                />
              </Grid>
            ))}
            {
              <Grid xs={12} style={{ textAlign: 'center' }}>
                <SdmIconAndTextButton
                  style={{ marginTop: 30, minWidth: 190, height: 45 }}
                  variant="redFill"
                  disabled={!selected}
                  text={
                    <SdmTypography
                      style={{ color: theme.colors.greyScale.white }}
                    >
                      {i18n.buttons.send.label}
                    </SdmTypography>
                  }
                  onClick={onSendAnswer}
                />
              </Grid>
            }
          </Grid>
        ) : (
          <Grid
            container
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            spacing={1}
          >
            <SdmTypography
              style={{ font: 'normal normal 300 54px/36px Roboto' }}
              textAlign="center"
            >
              {data ? `${i18n.alert.noMoreQuestion.label}` : null}
            </SdmTypography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { Survey };
