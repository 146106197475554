import React from 'react';

import { styled } from '@material-ui/core/styles';

import { theme } from '../../theme';

/* import { SdmIconAndTextButton, ArrowBackIcon } from '../../components'; */

const LeftBottomControlsContainer = styled('div')({
  display: 'flex',
});

/* const StyledButton = styled(SdmIconAndTextButton)({
  '& span': {
    '& div': {
      color: theme.colors.greyScale.white,
    },
  },
}); */

const TopControls = ({ /* title, navigateBack, */ topControlHeight }) => {
  //Styles
  const ControlContainer = styled('div')({
    color: theme.colors.greyScale.black,
    backgroundColor: 'transparent',
    width: 'fit-content',
    height: topControlHeight,
    justifyContent: 'space-between',
    position: 'absolute',
    display: 'flex',
    zIndex: 1200,
  });

  return (
    <ControlContainer>
      <LeftBottomControlsContainer>
        {/* <StyledButton variant="none" text={title || ''} onClick={navigateBack}>
          <ArrowBackIcon
            fontSize={'large'}
            htmlColor={theme.colors.greyScale.white}
          />
        </StyledButton> */}
      </LeftBottomControlsContainer>
    </ControlContainer>
  );
};

export { TopControls };
