import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';

import { SettingsDivider } from './SettingsDivider';
import {
  SdmIconButton,
  SdmRadio,
  SdmCheckbox,
  SdmTypography,
  SdmRadioButton,
  SdmFormControlLabel,
} from './../../../components';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Tooltip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { translation } from './../../../utils';

const MainContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - (120px + 45px))',
  paddingTop: 10,
});

const TabSettings = ({
  audioInputs,
  videoInputs,
  currentAudioInput,
  currentVideoInput,
  handleInputAudio,
  handleInputVideo,
  isSoundNotificationActive,
  handleSoundNotification,
  displayControls,
  fetchDevices,
}) => {
  //Hooks
  const intl = useIntl();
  const i18n = translation.breakoutRoom.tabSettings(intl);

  useEffect(() => {
    fetchDevices?.();
  }, []);

  return (
    <MainContainer>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: 10,
          }}
        >
          <Tooltip title={i18n.group.refresh.label}>
            <SdmIconButton onClick={fetchDevices} variant="white">
              <AutorenewIcon />
            </SdmIconButton>
          </Tooltip>
        </div>
        {displayControls ? (
          <>
            <SettingsDivider text={i18n.group.microphone.label} />
            <SdmRadio
              value={currentAudioInput}
              onChange={(e) => handleInputAudio(e.target.value.toString())}
              formControlWidth="calc(100% - 10px)"
              style={{
                marginLeft: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {audioInputs.map((ai, idx) => (
                <SdmFormControlLabel
                  key={idx}
                  value={ai.deviceId}
                  control={<SdmRadioButton />}
                  label={ai.label}
                />
              ))}
            </SdmRadio>

            <SettingsDivider text={i18n.group.camera.label} />
            <SdmRadio
              value={currentVideoInput || videoInputs?.[0]?.deviceId}
              onChange={(e) => handleInputVideo(e.target.value.toString())}
              formControlWidth="calc(100% - 10px)"
              style={{
                marginLeft: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {videoInputs.map((ai, idx) => (
                <SdmFormControlLabel
                  key={idx}
                  value={ai.deviceId}
                  control={<SdmRadioButton />}
                  label={ai.label}
                />
              ))}
            </SdmRadio>
          </>
        ) : null}
        <SettingsDivider text={i18n.group.notifications.label} />
        <div
          style={{
            display: 'flex',
            marginLeft: 16,
            marginTop: 10,
            alignItems: 'center',
          }}
        >
          <SdmCheckbox
            variant={'white'}
            onClick={() => handleSoundNotification(!isSoundNotificationActive)}
            checked={isSoundNotificationActive}
          />
          <SdmTypography margin={0} style={{ marginLeft: 8 }}>
            {i18n.group.notifications.playText.label}
          </SdmTypography>
        </div>
      </div>
    </MainContainer>
  );
};

export { TabSettings };
