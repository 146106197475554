import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Reactions } from './Reactions';

import {
  HeartIcon,
  BookIcon,
  HandIcon,
  DocumentIcon,
} from './../../components';

import { styled } from '@material-ui/core/styles';

const ButtonContainer = styled('div')({
  width: 'fit-content',
});

const iconStyle = {
  cursor: 'pointer',
  width: '3.5rem',
  height: '3.5rem',
  margin: '0.25rem 0',
  backgroundColor: 'rgba(0,0,0,0.6)',
  borderRadius: 32,
};

const ReactionComponent = ({
  onClick,
  containerStyle,
  isOpenReactions,
  onReactionClick,
  isReactionEnabled,
}) => {
  return isReactionEnabled ? (
    <div style={{ display: 'flex' }}>
      <div style={{ ...containerStyle, cursor: 'pointer' }}>
        <HeartIcon onClick={onClick} style={iconStyle} />
      </div>
      <Reactions open={isOpenReactions} onReactionClick={onReactionClick} />
    </div>
  ) : null;
};

const BookComponent = ({ onClick, containerStyle }) => {
  return (
    <div style={{ display: 'flex', cursor: 'pointer', ...containerStyle }}>
      <BookIcon onClick={onClick} style={iconStyle} />
    </div>
  );
};

const HandComponent = ({ onClick, containerStyle, isQAEnabled }) => {
  return isQAEnabled ? (
    <div style={{ display: 'flex', cursor: 'pointer', ...containerStyle }}>
      <HandIcon onClick={onClick} style={iconStyle} />
    </div>
  ) : null;
};

const DocumentComponent = ({ onClick, containerStyle, isPollingEnabled }) => {
  return isPollingEnabled ? (
    <div style={{ display: 'flex', cursor: 'pointer', ...containerStyle }}>
      <DocumentIcon onClick={onClick} style={iconStyle} />
    </div>
  ) : null;
};

const buttons = [
  {
    id: 1,
    IconComponent: React.memo(BookComponent),
    type: 'box',
  },
  {
    id: 2,
    IconComponent: React.memo(ReactionComponent),
    type: 'heart',
  },
  {
    id: 3,
    IconComponent: React.memo(HandComponent),
    type: 'hand',
  },
  {
    id: 4,
    IconComponent: React.memo(DocumentComponent),
    type: 'document',
  },
];

const LeftButtonsComponent = ({
  onClick,
  lastSelected,
  setLastSelected,
  isOpenReactions,
  onReactionClick,
  isReactionEnabled,
  isPollingEnabled,
  isQAEnabled,
}) => {
  //Functions
  const handleButtonClick = (item) => {
    setLastSelected(lastSelected?.type === item.type ? null : item);
    onClick && onClick(item);
  };

  return (
    <ButtonContainer>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        style={{ textAlign: 'center', paddingLeft: 20 }}
      >
        {buttons.map((b) => (
          <Grid item key={b.id} xs={12}>
            <b.IconComponent
              htmlColor="white"
              style={iconStyle}
              onClick={() => handleButtonClick(b)}
              containerStyle={{
                opacity: b?.type === lastSelected?.type ? 1 : 0.5,
              }}
              {...{
                isOpenReactions,
                onReactionClick,
                isReactionEnabled,
                isQAEnabled,
                isPollingEnabled,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </ButtonContainer>
  );
};

const LeftButtons = React.memo(LeftButtonsComponent);

export { LeftButtons };
