import aws from './aws';
const { API } = aws;

const listSessionsBySlug = /* GraphQL */ `
  query ListSessionsBySlug($slug: String, $nextToken: String) {
    listSessionsBySlug(slug: $slug, nextToken: $nextToken) {
      nextToken
      items {
        id
        externalId
        slug
      }
    }
  }
`;

export const useSessions = ({ showLoader, hideLoader }) => {
  const get = (slug, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader?.();

      API.post(listSessionsBySlug, { slug }, true, true)
        .then((response) => {
          resolve(response.data.listSessionsBySlug.items[0]);
        })
        .catch((e) => {
          console.error('list-session-by-slug', e);
          reject();
        })
        .finally(() => useLoader && hideLoader?.());
    });

  return { get };
};
