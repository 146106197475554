import { useState, useEffect, useRef, useCallback } from 'react';
import { nanoid } from 'nanoid';

const createHandlerSetter = (handlerValue) => {
  const handlerRef = useRef(handlerValue);

  // since useRef accepts an initial-value only, this is needed to make sure
  handlerRef.current = handlerValue;

  const setHandler = useCallback((nextCallback) => {
    handlerRef.current = nextCallback;
  });

  return [handlerRef, setHandler];
};

const useChat = ({
  client,
  userUuid,
  userMetadata,
  presChannel,
  msgChannel,
  signalChannel,
  isOnline,
  onSignal,
  onJoin,
  onLeave,
  showLoader,
  hideLoader,
}) => {
  const [isMsgSubscribed, setIsMsgSubscribed] = useState(false);
  const [isPresSubscribed, setIsPresSubscribed] = useState(false);
  const [isStateUserSet, setIsStateUserSet] = useState(false);
  const [messages, setMessages] = useState([]);
  const [occupants, setOccupants] = useState([]);
  const [occupancy, setOccupancy] = useState(0);

  // set signal callback
  const [onSignalRef, setOnSignalRef] = createHandlerSetter(onSignal);

  useEffect(() => {
    setOnSignalRef(onSignal);
  }, [onSignal]);

  // presence channel effects
  useEffect(() => {
    if (!presChannel || !isStateUserSet) return;
    client.hereNow(
      {
        channels: [presChannel],
        includeState: true,
        includeUUIDs: true,
      },
      (status, response) => {
        if (!response) return;
        const nextOccupants = response.channels[presChannel].occupants.filter(
          (o) => o.state.isOnline === true
        );
        setOccupants(nextOccupants);
        setOccupancy(nextOccupants?.length ?? 0);
      }
    );
  }, [presChannel, isStateUserSet]);

  useEffect(() => {
    if (!presChannel) return;
    const actions = {
      join: ({ occupancy }) => setOccupancy(occupancy),
      leave: ({ occupancy }) => setOccupancy(occupancy),
      timeout: ({ occupancy }) => setOccupancy(occupancy),
      interval: ({ occupancy }) => setOccupancy(occupancy),
      'state-change': ({ uuid, state, occupancy }, currOccupants) => {
        const nextOccupants = currOccupants.filter((o) => o.uuid !== uuid);
        if (state.isOnline) {
          nextOccupants.push({ uuid, state });
        }
        setOccupancy(occupancy);
        return nextOccupants;
      },
    };

    const listener = {
      presence: (p) => {
        if (p.action !== 'state-change') {
          actions?.[p?.action]?.(p);
        } else {
          actions?.[p?.action] &&
            setOccupants((currOccupants) =>
              actions[p.action](p, currOccupants)
            );
        }
      },
    };

    client.addListener(listener);
    try {
      client.subscribe({
        channels: [presChannel],
        withPresence: true,
      });
      setIsPresSubscribed(true);
    } catch (error) {
      console.log(error);
    }

    return () => {
      client.setState(
        {
          state: { isOnline: false, user: userMetadata },
          channels: [presChannel],
        },
        (status, response) => {
          if (status.error) {
            console.error(status, response);
            return;
          }
        }
      );

      client.removeListener(listener);
      client.unsubscribe({ channels: [presChannel] });
      setIsPresSubscribed(false);
    };
  }, [presChannel]);

  // message channel effects
  useEffect(() => {
    if (!isMsgSubscribed) return;

    fetchMessage();
  }, [msgChannel, isMsgSubscribed]);

  useEffect(() => {
    if (msgChannel && client) {
      const listener = {
        status: function (statusEvent) {
          if (statusEvent.category === 'PNConnectedCategory') {
            var newState = {
              new: 'state',
            };
            client.setState(
              {
                state: newState,
              },
              function (status) {
                console.log('Chat Subscribed');
              }
            );
          }
        },
        message: (m) => {
          if (m) {
            setMessages((currMsgs) => {
              return [...currMsgs, m];
            });
          }
        },
        messageAction: (action) => {
          if (action?.data) {
            fetchMessage();
          }
        },
      };
      client.addListener(listener);
      client.subscribe({
        channels: [msgChannel],
      });
      setIsMsgSubscribed(true);

      return () => {
        client.removeListener(listener);
        client.unsubscribe({ channels: [msgChannel] });
      };
    }
  }, [msgChannel]);

  const fetchMessage = async () => {
    const tempMessages = await new Promise((resolve, reject) => {
      client.fetchMessages(
        { channels: [msgChannel], count: 25 },
        (status, response) => {
          if (status.error) reject(status.error);
          const nextMessages = response?.channels[msgChannel];
          resolve(nextMessages || []);
        }
      );
    });
    const moderated = await new Promise((resolve, reject) => {
      client.getMessageActions(
        {
          channel: msgChannel,
        },
        (status, response) => {
          if (status.error) reject(status.error);
          if (response) {
            const historicalModerated = response?.data;
            let tempModerated = tempMessages;
            historicalModerated.map((action) => {
              tempModerated = updateMessageSignal(action, tempModerated);
            });
            resolve(tempModerated);
          }
        }
      );
    });

    setMessages(moderated);
  };

  const updateMessageSignal = (action, list) => {
    if (action?.type === 'updated' && list) {
      const { messageTimetoken, value } = action;
      const msgIdx = list.findIndex((m) => {
        return m.timetoken === messageTimetoken;
      });
      if (msgIdx >= 0) {
        const messageObject = list[msgIdx];
        messageObject.message.content.text = value;
        //If it has been moderated, we add a flag to intercept the graphic part
        messageObject.isModerated = true;
        list[msgIdx] = messageObject;
      }
    }
    return list;
  };

  // useEffect(() => {
  //   console.log('SONO NEL EFFECCT DI MSG CHANNEL');
  //   if (!msgChannel) return;
  //   showLoader?.();
  //   const listener = {
  //     message: (m) => {
  //       console.log('SONO IN MESSAGE LISTENER, M:: ', m);
  //       if (m) {
  //         setMessages((currMsgs) => {
  //           return [...currMsgs, m];
  //         });
  //       }
  //     },
  //     messageAction: (action) => {
  //       if (action?.data) {
  //         fetchMessage();
  //       }
  //     },
  //   };

  //   const timetoken = pubNubTimeToken();
  //   client.addListener(listener);
  //   try {
  //     client.subscribe({
  //       channels: [msgChannel],
  //       timetoken: timetoken,
  //     });

  //     setIsMsgSubscribed(true);
  //   } catch (error) {
  //     console.log('SUBSCRIBE msgChannel ERROR:: ', error);
  //   }

  //   hideLoader?.();

  //   // action when i join
  //   onJoin?.();

  //   console.log('È ARRIVATO IL MSGCHANNEL:: ', msgChannel);
  //   console.log('QUESTO È IL LISTENER:: ', listener);
  //   console.log('QUESTO È IL CLIENT:: ', client);
  //   return () => {
  //     // action when i leave
  //     onLeave?.();

  //     client.removeListener(listener);
  //     client.unsubscribe({ channels: [msgChannel] });
  //     setIsMsgSubscribed(false);
  //   };
  // }, [msgChannel]);

  useEffect(() => {
    if (!signalChannel) return;
    const listener = {
      signal: (s) => {
        s && onSignalRef?.current && onSignalRef?.current(s);
      },
    };

    const timetoken = pubNubTimeToken();
    client.addListener(listener);
    try {
      client.subscribe({
        channels: [signalChannel],
        timetoken: timetoken,
      });

      setIsMsgSubscribed(true);
    } catch (error) {
      console.log(error);
    }

    return () => {
      client.removeListener(listener);
      client.unsubscribe({ channels: [signalChannel] });
    };
  }, [signalChannel]);

  useEffect(() => {
    if (!presChannel || !isPresSubscribed) return;
    client.setState(
      {
        state: { isOnline: isOnline, user: userMetadata },
        channels: [presChannel],
      },
      (status, response) => {
        if (status.error) {
          console.error(status, response);
          return;
        }
        setIsStateUserSet(true);
      }
    );
  }, [presChannel, isOnline, userMetadata, isPresSubscribed]);

  const pubNubTimeToken = () => {
    const timetoken = Math.floor(Date.now() * 10000);
    return timetoken;
  };

  const publish = (text, callback) => {
    client.publish(
      {
        channel: [msgChannel],
        message: {
          id: nanoid(),
          author: userUuid,
          content: text,
        },
      },
      async (status, response) => {
        //        await client.auth();

        callback?.({ status, response });
      }
    );
  };

  const publishSignal = (text, callback) => {
    client.signal(
      {
        channel: [signalChannel],
        message: text,
      },
      (status, response) => {
        callback?.({ status, response });
      }
    );
  };

  const fetchOlderMessages = (callback) => {
    client.fetchMessages(
      {
        channels: [msgChannel],
        count: 25,
        start: messages?.[0]?.timetoken || pubNubTimeToken(),
      },
      (status, response) => {
        if (status.error) return;
        const nextMessages = response?.channels[msgChannel];
        if (nextMessages) {
          setMessages([...nextMessages, ...messages]);
        }
        callback?.();
      }
    );
  };

  return {
    messages,
    occupancy,
    occupants,
    isMsgSubscribed,
    publish,
    publishSignal,
    fetchOlderMessages,
  };
};

export { useChat };
