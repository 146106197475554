import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import { theme } from '../theme';
import { styled } from '@material-ui/core/styles';

const variants = {
  black: {
    color: theme.colors.greyScale.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  white: {
    color: theme.colors.greyScale.white,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.white,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  grey: {
    color: theme.colors.greyScale.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.primary.grey,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  red: {
    color: theme.colors.greyScale.white,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
  none: {
    color: theme.colors.greyScale.black,
    '&:hover': {
      background: 'transparent !important',
    },
    '&:disabled': {
      color: theme.colors.greyScale.black,
      background: 'transparent !important',
      opacity: 0.4,
    },
  },
};

const CustomIconButton = styled(IconButton)(
  ({ variant = 'red', small = false, style }) => ({
    width: small ? '30px !important' : '40px !important',
    height: small ? '30px !important' : '40px !important',
    margin: '4px !important',
    borderRadius: '4px !important',
    ...(variant && variants[variant]),
    ...(style && style),
  })
);

const InnerSdmIconButton = ({ children, ...rest }, ref) => (
  <CustomIconButton ref={ref} {...rest} disableRipple disableFocusRipple>
    {children}
  </CustomIconButton>
);

const SdmIconButton = React.forwardRef(InnerSdmIconButton);

export { SdmIconButton };
