import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const EndCallIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
    >
      <g
        id="Ui/Elements/Icons/EndCall/Black"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M35.42,19.3417862 C31.236,15.3764528 25.7586667,13.1924528 20,13.1924528 C14.2413333,13.1924528 8.764,15.3764528 4.56666667,19.3537862 C4.2,19.7191195 4,20.2084528 4,20.7324528 C4,21.2604528 4.21066667,21.7631195 4.56666667,22.0977862 L7.73466667,25.2671195 C8.46666667,25.9964528 9.78133333,25.9751195 10.4613333,25.2977862 C11.452,24.3804528 12.544,23.6217862 13.6973333,23.0444528 C14.412,22.6991195 14.6666667,21.8537862 14.6666667,21.1791195 L14.6666667,17.6777862 C16.3666667,17.2204528 18.3,17.1791195 20,17.1791195 C21.8013333,17.1791195 23.6853333,17.2204528 25.3333333,17.6737862 L25.3333333,21.1791195 C25.3333333,22.0644528 25.6906667,22.7657862 26.2826667,23.0537862 C27.488,23.6564528 28.584,24.4137862 29.5346667,25.3057862 C29.8973333,25.6484528 30.3906667,25.8457862 30.8866667,25.8457862 C31.4106667,25.8457862 31.9,25.6457862 32.2653333,25.2804528 L35.4333333,22.1124528 C35.7986667,21.7471195 36,21.2577862 36,20.7324528 C36,20.2084528 35.8,19.7191195 35.42,19.3417862 Z"
          id="Path"
          fill="#000000"
          fillRule="nonzero"
        ></path>
      </g>
    </SvgIcon>
  );
};
