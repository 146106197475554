import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const Options = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
    >
      <g
        id="Ui/Elements/Icons/Option/Black"
        style={{
          stroke: 'none',
          strokeWidth: '1',
          fill: 'none',
          fillRule: 'evenodd',
        }}
      >
        <path
          d="M20.1578947,26.3157895 C21.7275461,26.3157895 23,27.5882433 23,29.1578947 C23,30.7275461 21.7275461,32 20.1578947,32 C18.5882433,32 17.3157895,30.7275461 17.3157895,29.1578947 C17.3157895,27.5882433 18.5882433,26.3157895 20.1578947,26.3157895 Z M20.1578947,17.1578947 C21.7275461,17.1578947 23,18.4303486 23,20 C23,21.5696514 21.7275461,22.8421053 20.1578947,22.8421053 C18.5882433,22.8421053 17.3157895,21.5696514 17.3157895,20 C17.3157895,18.4303486 18.5882433,17.1578947 20.1578947,17.1578947 Z M20.1578947,8 C21.7275461,8 23,9.27245387 23,10.8421053 C23,12.4117567 21.7275461,13.6842105 20.1578947,13.6842105 C18.5882433,13.6842105 17.3157895,12.4117567 17.3157895,10.8421053 C17.3157895,9.27245387 18.5882433,8 20.1578947,8 Z"
          id="Combined-Shape"
          style={{
            fill: '#000000',
            fillRule: 'nonzero',
          }}
        ></path>
      </g>
    </SvgIcon>
  );
};
