import React from 'react';

import {
  SdmTypography,
  SdmIconAndTextButton,
  EyeIcon,
  ArrowBackIcon,
} from './../../components';

import { theme } from './../../theme';
import { styled } from '@material-ui/core/styles';

const REACT_APP_WEBINAR_BACK_REDIRECT =
  process.env.REACT_APP_WEBINAR_BACK_REDIRECT;

const peopleWatchingIconStyle = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  marginLeft: 10,
  marginRight: 10,
  pointerEvents: 'none',
};

const ControlContainer = styled('div')({
  color: theme.colors.greyScale.white,
  position: 'absolute',
  top: 25,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

const LeftTopControlsContainer = styled('div')({
  marginLeft: 25,
});

const RightTopControlsContainer = styled('div')({
  display: 'flex',
  marginRight: 25,
});

const TopControlsComponent = ({
  peopleWatching,
  hideWatching,
  isChatEnabled,
}) => {
  return (
    <ControlContainer>
      <LeftTopControlsContainer>
        <SdmIconAndTextButton
          variant="none"
          text={''}
          onClick={() => {
            window.location.href = REACT_APP_WEBINAR_BACK_REDIRECT;
          }}
        >
          <ArrowBackIcon
            fontSize={'large'}
            htmlColor={theme.colors.greyScale.white}
          />
        </SdmIconAndTextButton>
      </LeftTopControlsContainer>

      <RightTopControlsContainer>
        {/* PEOPLE WATCHING BUTTON */}
        {!hideWatching && isChatEnabled ? (
          <SdmIconAndTextButton
            variant="blackTissueFill"
            style={peopleWatchingIconStyle}
            text={
              <SdmTypography style={{ color: theme.colors.greyScale.white }}>
                {peopleWatching}
              </SdmTypography>
            }
            onClick={() => console.log('on exit')}
          >
            <EyeIcon htmlColor={theme.colors.greyScale.white} />
          </SdmIconAndTextButton>
        ) : null}
      </RightTopControlsContainer>
    </ControlContainer>
  );
};

const TopControls = React.memo(TopControlsComponent);

export { TopControls };
