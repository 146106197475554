import aws from './aws';
import { useQuestion } from './../utils/questionGqlHelper';

export const useWebinarInteraction = ({ showLoader, hideLoader }) => {
  const questionGqlHelper = useQuestion({ showLoader, hideLoader });

  const getQuestions = ({ user, session }, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader?.();

      questionGqlHelper
        .listByUserId(user?.id, session?.id, useLoader)
        .then(resolve)
        .catch(reject)
        .finally(() => useLoader && hideLoader?.());
    });

  const postQuestion = (
    { user, session, question, isAnonymous },
    useLoader = true
  ) =>
    new Promise((resolve, reject) => {
      const input = {
        isAnonymous: isAnonymous,
        sessionId: session?.id,
        text: question,
        userFirstname: isAnonymous ? '' : user?.FirstName,
        userId: user?.id,
        userLastname: isAnonymous ? '' : user?.LastName,
      };

      useLoader && showLoader?.();

      questionGqlHelper
        .create(input, useLoader)
        .then(resolve)
        .catch(reject)
        .finally(() => useLoader && hideLoader?.());
    });

  const getPoll = ({ user, sessionId }, useLoader = true) =>
    new Promise((resolve, reject) => {
      const apiName = 'webinarInteraction';
      const path = `/getPolls`;
      const { accessToken } = user;
      const input = { body: { accessToken, idSession: sessionId } };

      useLoader && showLoader?.();

      aws.standardAPI
        .post(apiName, path, input)
        .then(resolve)
        .catch(reject)
        .finally(() => useLoader && hideLoader?.());
    });

  const postPoll = ({ user, sessionId, idPoll, idAnswer }, useLoader = true) =>
    new Promise((resolve, reject) => {
      const apiName = 'webinarInteraction';
      const path = `/postPoll`;
      const { accessToken } = user;
      const input = {
        body: {
          accessToken,
          idUser: user.id,
          idAnswer: idPoll,
          idPoll: idAnswer,
          idSession: sessionId,
        },
      };

      useLoader && showLoader?.();

      aws.standardAPI
        .post(apiName, path, input)
        .then(resolve)
        .catch(reject)
        .finally(() => useLoader && hideLoader?.());
    });

  return { getQuestions, postQuestion, getPoll, postPoll };
};
