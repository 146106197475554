const scalingFactor = 8;

export const theme = {
  spacing: (value) => value * scalingFactor,
  colors: {
    primary: {
      red: '#E30613',
    },
    secondary: {
      red: '#E2001A',
    },
    greyScale: {
      white: '#FFFFFF',
      grey: '#D8D8D8',
      darkGray: '#979797',
      backgroundGrey: '#191919',
      black: '#000000',
    },
    dark: {
      red: '#B40014',
    },
  },
};
