import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { theme } from './../../theme';

export const CameraActiveIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
    >
      <g
        id="Ui/Elements/Icons/Camera/Active/White"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M23.4970182,12.75 C24.0030568,12.75 24.4613262,12.9552237 24.7930835,13.2869437 C25.1247538,13.6185766 25.33,14.0766802 25.33,14.5826909 L25.33,14.5826909 L25.33,17.2463792 L31.25,13.8284883 L31.25,25.6624208 L25.33,22.2445298 L25.33,24.9140364 C25.33,25.4202452 25.1248113,25.8784195 24.7931571,26.2100736 C24.461394,26.5418368 24.0030885,26.7470182 23.4970182,26.7470182 L23.4970182,26.7470182 L10.5826909,26.7470182 C10.07652,26.7470182 9.61832551,26.5419572 9.2866916,26.210286 C8.95504338,25.8786004 8.75,25.4203277 8.75,24.9140364 L8.75,24.9140364 L8.75,14.5826909 C8.75,14.0766725 8.95514528,13.6184603 9.28683671,13.2867689 C9.61845545,12.9551502 10.0765801,12.75 10.5826909,12.75 L10.5826909,12.75 Z"
          id="Path"
          stroke={props.htmlColor || theme.colors.greyScale.white}
          strokeWidth="1.5"
          fillRule="nonzero"
        ></path>
      </g>
    </SvgIcon>
  );
};
