import React, { useContext, useState, useEffect } from 'react';

import { SdmTypography } from './../../../components';

import { styled } from '@material-ui/core/styles';

import format from 'date-fns/format';

import { Question } from './questionAnswers/index';

import { AuthContext, LoaderContext } from './../../../contexts';

import { useWebinarInteraction } from '../../../utils/webinarInteractionHelper';

const MainContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 200px)',
});

const QuestionsAnswers = ({ i18n, session }) => {
  //State
  const [questions, setQuestions] = useState([]);

  //Contexts
  const { user } = useContext(AuthContext);
  const { setLoaderActive } = useContext(LoaderContext);

  // Loader Functions
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);

  // Hooks
  const { getQuestions } = useWebinarInteraction({ showLoader, hideLoader });

  //Effects
  useEffect(() => {
    fetchQuestions();
  }, []);

  //Functions
  const fetchQuestions = async () => {
    const res = await getQuestions({ user, session });

    const parsedQuestion = res
      .sort((a, b) => {
        var dateA = new Date(a?.createdAt),
          dateB = new Date(b?.createdAt);
        return dateB - dateA;
      })
      .map((i) => {
        const formatData = i.createdAt
          ? format(new Date(i.createdAt), 'HH:mm')
          : '-';

        return {
          id: i.id,
          time: formatData,
          question: i.text,
          givenName: i.userFirstname,
          familyName: i.userLastname,
          isMine: i.userId === user.id,
        };
      });

    setQuestions(parsedQuestion);
  };

  return (
    <MainContainer>
      <SdmTypography variant="h4" margin={0}>
        {i18n.questions.label}
      </SdmTypography>
      {questions.map(
        ({ id, time, isMine, question, givenName, familyName }) => (
          <Question
            key={id}
            i18n={i18n.question}
            isMine={isMine}
            {...{ time, question, givenName, familyName }}
          />
        )
      )}
    </MainContainer>
  );
};

export { QuestionsAnswers };
