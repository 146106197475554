import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Layout2 = (props) => (
  <SvgIcon
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    style={{
      transform: 'rotate(90deg)',
      fontSize: '2rem',
      ...props.style,
    }}
  >
    <g
      id="Ui/Elements/Icons/Layout/Tile/Black"
      style={{
        stroke: 'none',
        strokeWidth: '1',
        fill: 'none',
        fillRule: 'evenodd',
      }}
    >
      <path
        d="M30,30.1538462 C30,31.1734488 29.3112073,32 28.4615385,32 L28.4615385,32 L11.5384615,32 C10.6887927,32 10,31.1734488 10,30.1538462 L10,30.1538462 L10,9.84615385 C10,8.82655123 10.6887927,8 11.5384615,8 L11.5384615,8 L28.4615385,8 C29.3112073,8 30,8.82655123 30,9.84615385 L30,9.84615385 Z M19,21 L11.666,21 L11.6666667,29.3448276 C11.6666667,29.8576634 12.0527069,30.2803347 12.5500455,30.3380999 L12.6666667,30.3448276 L19,30.344 L19,21 Z M28.333,21 L21,21 L21,30.344 L27.3333333,30.3448276 C27.8461692,30.3448276 28.2688405,29.9587874 28.3266056,29.4614487 L28.3333333,29.3448276 L28.333,21 Z M19,9.655 L12.6666667,9.65517241 C12.1143819,9.65517241 11.6666667,10.1028877 11.6666667,10.6551724 L11.6666667,10.6551724 L11.666,19 L19,19 L19,9.655 Z M27.3333333,9.65517241 L21,9.655 L21,19 L28.333,19 L28.3333333,10.6551724 C28.3333333,10.1423366 27.9472931,9.71966525 27.4499545,9.66190015 L27.3333333,9.65517241 Z"
        id="Combined-Shape"
        style={{
          fill: '#000000',
          fillRule: 'nonzero',
          transform:
            'translate(20.000000, 20.000000) rotate(90.000000) translate(-20.000000, -20.000000)',
        }}
      ></path>
    </g>
  </SvgIcon>
);

export { Layout2 };
