import React, { useContext, useRef, useEffect, useState } from 'react';

import { styled } from '@material-ui/core/styles';

import { Message, MessageInput } from './social/index';
import { isMobileOnly } from 'react-device-detect';
import { AuthContext } from './../../../contexts';
import { useChatModerator } from './../../../utils/moderatorHelper';
import { checkLanguage } from './../../../utils/utility';
import Cookies from 'js-cookie';
import { SdmTypography, SdmCircularLoader } from '../../../components';
import { theme } from './../../../theme';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 200px)',
});

const ChatContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
});

const MessageInputContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
});

const Filler = styled('div')({
  flexGrow: 1,
});

const isElementInViewport = (el) => {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const Social = ({ i18n, messages = [], publish, session, hasBeenBanned }) => {
  const { user } = useContext(AuthContext);
  const chatContainerRef = useRef();
  const hiddenDivTop = useRef();
  const hiddenDivBottom = useRef();
  const { accessToken } = JSON.parse(Cookies.get('sdm-auth') || '{}');
  const moderatorService = useChatModerator({
    accessToken: accessToken,
    sessionId: session.id,
    channel: session.id,
    isMobile: isMobileOnly,
    language: checkLanguage(),
  });

  const [blackList, setBlackList] = useState();

  useEffect(() => {
    // auto scroll chat
    if (hiddenDivTop.current && hiddenDivBottom.current) {
      const isHiddenDivTopInViewport = isElementInViewport(
        hiddenDivTop.current
      );
      const isHiddenDivBottomInViewport = isElementInViewport(
        hiddenDivBottom.current
      );
      (isHiddenDivTopInViewport || isHiddenDivBottomInViewport) &&
        scrollChatToBottom();
    }
  }, [messages]);

  // Functions
  const scrollChatToBottom = () => {
    chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight -
      chatContainerRef.current.clientHeight;
  };

  useEffect(() => {
    if (session && session.isModerator) {
      //È arrivata la session, bisogna fetchare la blackList
      fetchBlackList();
    } else {
      setBlackList([]);
    }
  }, [session]);

  const fetchBlackList = async () => {
    const session = await moderatorService.getBlackList();
    const _blackList = session?.blackList?.items;
    setBlackList(_blackList || []);
  };

  const handleBan = async (id) => {
    const res = await moderatorService.kickUser(
      moderatorService.createAuthKey(id),
      id
    );
    await fetchBlackList();
    return res;
  };

  const handleUnban = async (id) => {
    const res = await moderatorService.restoreUser(
      moderatorService.createAuthKey(id)
    );
    return res;
  };

  const handleDelete = async (messageTimetoken, msg) => {
    const res = await moderatorService.purgeMessage(messageTimetoken, msg);
    return res;
  };

  const checkBanSituation = (id) => {
    if (!blackList) return false;
    const found = blackList.find((b) => b.uuid === id);
    return !!found;
  };

  const renderMessage = (m) => {
    switch (m?.message?.content?.type) {
      /* case 'sys':
        return (
          <ServiceMessage
            message={`${m?.message?.content?.userInfo?.user?.givenName} ${
              m?.message?.content?.userInfo?.user?.familyName
            } ${i18n.serviceMessages[m?.message?.content?.text]?.label}`}
          />
        ); */
      case 'chat':
        return (
          <Message
            message={m?.message?.content?.text}
            givenName={m?.message?.content?.userInfo?.user?.givenName}
            familyName={m?.message?.content?.userInfo?.user?.familyName}
            // avatar={m?.message?.content?.userInfo?.user?.image}
            isMine={user?.id === m?.message?.content?.userInfo?.id}
            isModerator={session.isModerator}
            isModerated={m?.isModerated}
            youTranslation={i18n.messages.youTranslation}
            i18n={i18n}
            onBan={() => handleBan(m?.message?.content?.userInfo?.id)}
            onUnban={() => handleUnban(m?.message?.content?.userInfo?.id)}
            onDelete={(msg) => handleDelete(m?.timetoken, msg)}
            bannedUser={checkBanSituation(m?.message?.content?.userInfo?.id)}
          />
        );
      default:
        break;
    }
  };

  return (
    <MainContainer>
      {blackList ? (
        <ChatContainer ref={chatContainerRef}>
          {messages.map((m, idx) => {
            return (
              <React.Fragment key={m?.message?.id || idx}>
                {idx === messages.length - 1 && <div ref={hiddenDivTop} />}
                {renderMessage(m, idx)}
                {idx === messages.length - 1 && <div ref={hiddenDivBottom} />}
              </React.Fragment>
            );
          })}
        </ChatContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SdmCircularLoader />
        </div>
      )}
      <Filler />
      <MessageInputContainer>
        {hasBeenBanned ? (
          <SdmTypography
            variant={'h4'}
            textAlign={'center'}
            style={{ color: theme.colors.primary.red }}
          >
            {i18n.bannedError}
          </SdmTypography>
        ) : (
          <MessageInput
            i18n={i18n.messageInput}
            publish={publish}
            scrollChatToBottom={scrollChatToBottom}
          />
        )}
      </MessageInputContainer>
    </MainContainer>
  );
};

export { Social };
