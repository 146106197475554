import React, { useContext, useState } from 'react';
import { styled, InputAdornment } from '@material-ui/core';

import { SdmTextField, SdmTypography } from './../../../../components';
import { theme } from './../../../../theme';
import { extractUserInfo } from './../../../../utils';
import { AuthContext } from './../../../../contexts';

import 'emoji-mart/css/emoji-mart.css';

const ChatSdmTextField = styled(SdmTextField)({
  backgroundColor: 'transparent',
  borderBottom: `1px solid rgba(255, 255, 255, 0.6)`,
  '& .MuiInput-input': {
    color: theme.colors.greyScale.white,
    fontSize: '1.250rem',
  },
});

const MessageInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const formControlStyle = { width: 'calc(100%)' };

export const MessageInput = ({ i18n, publish, scrollChatToBottom }) => {
  const { user } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const onSendMessage = () => {
    if (message && message.trim()) {
      setError(false);
      publish(
        {
          type: 'chat',
          text: message,
          userInfo: extractUserInfo(user),
        },
        (res) => {
          setError(res.status.error);
          if (!res.status.error) {
            setMessage('');
            scrollChatToBottom();
          }
        }
      );
    }
  };

  const handleKeyDown = (e) => {
    e.key === 'Enter' && onSendMessage();
  };

  return (
    <MessageInputContainer>
      <ChatSdmTextField
        error={error}
        helperText={error ? i18n.error.label : null}
        formControlStyle={formControlStyle}
        value={message}
        onChange={onMessageChange}
        onKeyDown={handleKeyDown}
        placeholder={i18n.placeholder}
        inputPropsStyle={{ marginTop: 0 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SdmTypography
                onClick={onSendMessage}
                variant="h2"
                margin={0}
                style={{ color: '#fff' }}
              >
                SEND
              </SdmTypography>
            </InputAdornment>
          ),
        }}
      />
    </MessageInputContainer>
  );
};
