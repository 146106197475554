import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { translation } from './../../utils';

import { SdmTypography } from './../../components';

import { theme } from './../../theme';
import { styled } from '@material-ui/core/styles';

import {
  TabHeader,
  SessionInfo,
  QuestionsAnswers,
  Social,
  useTabs,
} from './rightColumnTabs/index';

const TabHeaderContainer = styled('div')({
  display: 'flex',
  paddingRight: 20,
  paddingLeft: 20,
  paddingBottom: 40,
});

const TabContentContainer = styled('div')({
  color: theme.colors.greyScale.white,
  marginTop: 5,
});

const RightColumnTabs = ({ messages, publish, session, hasBeenBanned }) => {
  // Hooks
  const intl = useIntl();
  const i18n = translation.webinar.rightColumnTabs(intl);
  const tabs = useTabs(
    i18n.tabHeaders,
    session?.isChatEnabled,
    session?.isQAEnabled
  );

  // States
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // Functions
  const onClickTabHeader = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <SdmTypography
        variant="h1"
        style={{
          lineHeight: 1,
          color: theme.colors.greyScale.white,
          marginTop: 50,
          marginBottom: 25,
        }}
        textAlign="center"
      >
        {session?.name}
      </SdmTypography>
      <TabHeaderContainer>
        {tabs
          .filter((t) => !t.hidden)
          .map(({ id, name }) => (
            <TabHeader
              key={id}
              name={name}
              isSelected={selectedTab.id === id}
              onClickTabHeader={() => onClickTabHeader({ id, name })}
              tabsCount={tabs.filter((t) => !t.hidden)?.length}
            />
          ))}
      </TabHeaderContainer>
      {selectedTab?.id === 1 ? (
        <TabContentContainer>
          <SessionInfo i18n={i18n.tabs.info} session={session} />
        </TabContentContainer>
      ) : null}
      {selectedTab?.id === 2 ? (
        <TabContentContainer>
          <QuestionsAnswers i18n={i18n.tabs.qa} session={session} />
        </TabContentContainer>
      ) : null}
      {selectedTab?.id === 3 ? (
        <TabContentContainer>
          <Social
            i18n={i18n.tabs.social}
            messages={messages}
            publish={publish}
            session={session}
            hasBeenBanned={hasBeenBanned}
          />
        </TabContentContainer>
      ) : null}
    </>
  );
};

export { RightColumnTabs };
