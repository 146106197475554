import React from 'react';
import { useTrail, a } from 'react-spring';

import { constants } from './../../utils';

import {
  HeartIcon,
  ThumbupIcon,
  SmileIcon,
  ApplauseIcon,
} from './../../components';

const iconStyle = {
  cursor: 'pointer',
  width: '3.5rem',
  height: '3.5rem',
  margin: '0.1rem 0',
};

const TrailComponent = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 40 : 0,
    width: open ? 'auto' : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      <div
        style={{
          display: open ? 'flex' : 'none',
          height: 59,
          background: 'rgba(0,0,0,0.2)',
          borderRadius: 32,
        }}
      >
        {trail.map(({ x, width, height, ...rest }, index) => (
          <a.div
            key={items[index].key}
            style={{
              ...rest,
              fontSize: 40,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <a.div style={{ height, width, padding: '0 0.5rem ' }}>
              {items[index]}
            </a.div>
          </a.div>
        ))}
      </div>
    </div>
  );
};

const Trail = React.memo(TrailComponent);

const ReactionsComponent = ({ open, onReactionClick }) => {
  return (
    <Trail open={open}>
      <ThumbupIcon
        style={iconStyle}
        onClick={() =>
          onReactionClick(constants.AgendaSessionLiveReactions.ThumbsUp)
        }
      />
      <SmileIcon
        style={iconStyle}
        onClick={() =>
          onReactionClick(constants.AgendaSessionLiveReactions.Smile)
        }
      />
      <HeartIcon
        style={iconStyle}
        onClick={() =>
          onReactionClick(constants.AgendaSessionLiveReactions.Heart)
        }
      />
      <ApplauseIcon
        style={iconStyle}
        onClick={() =>
          onReactionClick(constants.AgendaSessionLiveReactions.Applause)
        }
      />
    </Trail>
  );
};

const Reactions = React.memo(ReactionsComponent);

export { Reactions };
