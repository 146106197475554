import React, { useRef, useEffect, useContext, useState } from 'react';

import { usePubNub } from 'pubnub-react';

import { styled } from '@material-ui/core/styles';

import { AuthContext, LoaderContext } from './../../../contexts';

import { extractUserInfo, useChat } from './../../../utils';

import { Message, MessageInput } from './social/index';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - (120px + 45px))',
  paddingTop: 10,
  // Handle scrollbar properties
  '& ::-webkit-scrollbar': {
    width: 6,
  },
  '& ::-webkit-scrollbar-track': {
    background: '#ffffff',
  },
  '& ::-webkit-scrollbar-thumb': {
    background: '#36393F',
    borderRadius: 5,
  },
  '& ::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(149, 152, 154, 1)',
  },
});

const ChatContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
});

const MessageInputContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
});

const Filler = styled('div')({
  flexGrow: 1,
});

const isElementInViewport = (el) => {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const TabSocial = ({ i18n, displayControls, msgChannel, uuid }) => {
  //Loader Function
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);

  // Contexts
  const { user } = useContext(AuthContext);
  const { setLoaderActive } = useContext(LoaderContext);

  // Refs
  const chatContainerRef = useRef();
  const hiddenDivTop = useRef();
  const hiddenDivBottom = useRef();

  // States
  const [userMetadata, setUserMetadata] = useState();

  // Hooks
  const client = usePubNub();
  const {
    messages = [],
    publish,
    fetchOlderMessages,
    isMsgSubscribed,
  } = useChat({
    client,
    userUuid: uuid,
    userMetadata,
    isOnline: true,
    presChannel: msgChannel,
    msgChannel,
    /* onJoin: () => handleJoin(),
    onLeave: () => handleLeave(), */
    showLoader,
    hideLoader,
  });

  // Effects
  useEffect(() => {
    // auto scroll chat
    if (hiddenDivTop.current && hiddenDivBottom.current) {
      const isHiddenDivTopInViewport = isElementInViewport(
        hiddenDivTop.current
      );
      const isHiddenDivBottomInViewport = isElementInViewport(
        hiddenDivBottom.current
      );
      (isHiddenDivTopInViewport || isHiddenDivBottomInViewport) &&
        scrollChatToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (isMsgSubscribed) {
      fetchOlderMessages(() => {
        scrollChatToBottom();
      });
    }
  }, [isMsgSubscribed]);

  useEffect(() => {
    if (user) {
      const newMetadata = extractUserInfo(user);
      setUserMetadata(newMetadata);
    }
  }, [user]);

  // Functions
  const scrollChatToBottom = () => {
    chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight -
      chatContainerRef.current.clientHeight;
  };

  /* const handleJoin = () => {
    publish({
      type: 'sys',
      text: 'brJoin',
      userInfo: extractUserInfo(user),
    });
  };

  const handleLeave = () => {
    publish({
      type: 'sys',
      text: 'brLeave',
      userInfo: extractUserInfo(user),
    });
  };
 */

  const renderMessage = (m) => {
    switch (m?.message?.content?.type) {
      /* case 'sys':
        return (
          <ServiceMessage
            message={`${m?.message?.content?.userInfo?.user?.givenName} ${
              m?.message?.content?.userInfo?.user?.familyName
            } ${i18n.serviceMessages[m?.message?.content?.text]?.label}`}
          />
        ); */
      case 'chat':
        return (
          <Message
            message={m?.message?.content?.text}
            givenName={m?.message?.content?.userInfo?.user?.givenName}
            familyName={m?.message?.content?.userInfo?.user?.familyName}
            avatar={m?.message?.content?.userInfo?.user?.image}
            isMine={user?.id === m?.message?.content?.userInfo?.id}
          />
        );
      default:
        break;
    }
  };

  return (
    <MainContainer>
      <ChatContainer ref={chatContainerRef}>
        {messages.map((m, idx) => {
          return (
            <React.Fragment key={m?.message?.id || idx}>
              {idx === messages.length - 1 && <div ref={hiddenDivTop} />}
              {renderMessage(m)}
              {idx === messages.length - 1 && <div ref={hiddenDivBottom} />}
            </React.Fragment>
          );
        })}
      </ChatContainer>
      <Filler />
      {displayControls ? (
        <MessageInputContainer>
          <MessageInput
            i18n={i18n.messageInput}
            publish={publish}
            scrollChatToBottom={scrollChatToBottom}
          />
        </MessageInputContainer>
      ) : null}
    </MainContainer>
  );
};

export { TabSocial };
