import React, { createContext, useState } from 'react';
import { Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from './../theme';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1501,
    color: theme.colors.greyScale.white,
    display: 'flex',
    flexDirection: 'column',
  },
}));
const LoaderContext = createContext();

const LoaderContextProvider = ({ children }) => {
  //Hooks
  const classes = useStyles();

  //State
  const [isLoaderActive, setLoaderActive] = useState(false);

  return (
    <LoaderContext.Provider value={{ isLoaderActive, setLoaderActive }}>
      {children}
      <Backdrop
        className={classes.backdrop}
        open={isLoaderActive === true || isLoaderActive?.text != null}
      >
        {isLoaderActive?.text && (
          <Typography style={{ paddingBottom: 10 }}>
            {isLoaderActive?.text}
          </Typography>
        )}
        <CircularProgress style={{ color: theme.colors.primary.red }} />
      </Backdrop>
    </LoaderContext.Provider>
  );
};

export { LoaderContextProvider, LoaderContext };
