import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { isMobileOnly, isDesktop } from 'react-device-detect';

import { IntlProvider } from 'react-intl';
import * as Langs from './compiled-lang';

import { Breakoutroom, Webinar, PleaseRotate } from './pages';
import { LoaderContextProvider } from './contexts';

const App = () => {
  //State
  const [locale, setLocale] = useState('it');

  //Media query
  const isPortrait = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    const checkParams = async () => {
      if (location.search) {
        const params = new URLSearchParams(location.search);
        setLocale(params.get('lang') || 'it');
      }
    };

    checkParams();
  }, [location.pathname, location.search]);

  return (
    <IntlProvider locale={locale} messages={Langs[locale]}>
      <Router>
        <LoaderContextProvider>
          <Switch>
            <Route exact path={`/webinar/:slugId`}>
              {!isDesktop && isMobileOnly && isPortrait ? (
                <PleaseRotate />
              ) : (
                <Webinar />
              )}
            </Route>
            <Route exact path={`/breakoutroom/:breakoutroomId`}>
              <Breakoutroom />
            </Route>
          </Switch>
        </LoaderContextProvider>
      </Router>
    </IntlProvider>
  );
};

export default App;
