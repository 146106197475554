import React from 'react';

import { styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const CloseIconContainer = styled('div')({
  paddingTop: 10,
  paddingRight: 10,
  display: 'flex',
  justifyContent: 'flex-end',
});

const CloseTabsComponent = ({ onClose }) => {
  return (
    <CloseIconContainer onClick={onClose}>
      <CloseIcon
        viewBox="5 5 24 24"
        htmlColor="white"
        style={{ fontSize: '2rem', cursor: 'pointer' }}
      />
    </CloseIconContainer>
  );
};

const CloseTabs = React.memo(CloseTabsComponent);

export { CloseTabs };
