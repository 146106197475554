import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const SliderArrowRightIcon = (props) => (
  <SvgIcon
    {...props}
    style={{
      fontSize: 40,
      ...props.style,
    }}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
  >
    <g
      id="Ui/Elements/Icons/Slider/Slider-Arrow/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M20.0522327,25.6824794 C20.2392122,25.6717034 20.4170432,25.6003056 20.5572001,25.4797388 L31.7013194,15.7482022 C31.9350067,15.5579853 32.0433515,15.2593618 31.9840049,14.9690551 C31.9246583,14.6787483 31.7070442,14.4428569 31.4162216,14.353584 C31.1253989,14.2643111 30.8075485,14.3358326 30.5869086,14.5401928 L20,23.781773 L9.41309202,14.5401928 C9.1924522,14.3358322 8.87460153,14.2643105 8.58377868,14.3535834 C8.29295582,14.4428563 8.07534156,14.6787478 8.01599505,14.9690548 C7.95664853,15.2593618 8.0649936,15.5579854 8.29868129,15.7482022 L19.4427893,25.4797388 C19.6098561,25.6236171 19.8293257,25.696627 20.0522327,25.6824794 Z"
        id="Path"
        fill="#FFFFFF"
        fillRule="nonzero"
        transform="translate(20.000000, 20.000000) rotate(-90.000000) translate(-20.000000, -20.000000) "
      ></path>
    </g>
  </SvgIcon>
);

export const SliderArrowLeftIcon = (props) => (
  <SliderArrowRightIcon
    style={{ ...props.style, transform: 'rotate(180deg)' }}
  />
);
