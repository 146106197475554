import React from 'react';

import { SdmTypography } from './../../../../components';
import { styled } from '@material-ui/core/styles';

const MessageTypography = styled(SdmTypography)({
  opacity: 0.79,
  fontSize: '0.8rem',
  marginTop: 5,
});

const ServiceMessage = ({ message }) => {
  return <MessageTypography textAlign="center">{message}</MessageTypography>;
};

export { ServiceMessage };
