import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { theme } from './../../theme';

export const MicrophoneActiveIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
    >
      <g
        id="Ui/Elements/Icons/Microphone/Active/White"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M19.2068966,26.2063638 L18.7931034,26.2063638 C15.5936552,26.2063638 13,23.6127086 13,20.4132603 L13,17.9305017 C13,17.4736741 13.3707586,17.1029155 13.8275862,17.1029155 C14.2844138,17.1029155 14.6551724,17.4736741 14.6551724,17.9305017 C14.6551724,17.9305017 14.6551724,19.0456741 14.6551724,20.4132603 C14.6551724,22.6986397 16.5077241,24.5511914 18.7931034,24.5511914 C19.6107586,24.5511914 20.4582069,24.5511914 21.2758621,24.5511914 C23.5612414,24.5511914 25.4137931,22.6986397 25.4137931,20.4132603 C25.4137931,19.0456741 25.4137931,17.9305017 25.4137931,17.9305017 C25.4137931,17.4736741 25.7845517,17.1029155 26.2413793,17.1029155 C26.6982069,17.1029155 27.0689655,17.4736741 27.0689655,17.9305017 C27.0689655,17.9305017 27.0689655,19.0456741 27.0689655,20.4132603 C27.0689655,23.6127086 24.4753103,26.2063638 21.2758621,26.2063638 L20.862069,26.2063638 L20.862069,30.3442948 L23.3448276,30.3442948 C23.8016552,30.3442948 24.1724138,30.7150534 24.1724138,31.171881 C24.1724138,31.6287086 23.8016552,31.9994672 23.3448276,31.9994672 L16.7241379,31.9994672 C16.2673103,31.9994672 15.8965517,31.6287086 15.8965517,31.171881 C15.8965517,30.7150534 16.2673103,30.3442948 16.7241379,30.3442948 L19.2068966,30.3442948 L19.2068966,26.2063638 Z"
          id="Path"
          fill={props.htmlColor || theme.colors.greyScale.white}
        ></path>
        <path
          d="M23.7583062,11.3103488 C23.7583062,10.432003 23.4093843,9.59007097 22.788633,8.96993223 C22.1678812,8.34869008 21.3261011,8 20.4482258,8 C20.1737225,8 19.894763,8 19.6202597,8 C18.7423844,8 17.9006043,8.34869008 17.2798526,8.96993223 C16.6591012,9.59007097 16.3101793,10.432003 16.3101793,11.3103488 C16.3101793,13.676145 16.3101793,17.2204253 16.3101793,19.5862216 C16.3101793,20.4645674 16.6591012,21.3064994 17.2798526,21.9266383 C17.9006043,22.5478803 18.7423844,22.8965701 19.6202597,22.8965701 C19.894763,22.8965701 20.1737225,22.8965701 20.4482258,22.8965701 C21.3261011,22.8965701 22.1678812,22.5478803 22.788633,21.9266383 C23.4093843,21.3064994 23.7583062,20.4645674 23.7583062,19.5862216 L23.7583062,11.3103488 Z M22.1032662,11.3103488 L22.1032662,19.5862216 C22.1032662,20.0253943 21.9290279,20.4458085 21.6184294,20.7569813 C21.3078305,21.067051 20.8871634,21.2413961 20.4482258,21.2413961 C20.1737225,21.2413961 19.894763,21.2413961 19.6202597,21.2413961 C19.1813221,21.2413961 18.760655,21.067051 18.4500561,20.7569813 C18.1394577,20.4458085 17.9652193,20.0253943 17.9652193,19.5862216 C17.9652193,17.2204253 17.9652193,13.676145 17.9652193,11.3103488 C17.9652193,10.871176 18.1394577,10.4507617 18.4500561,10.1395889 C18.760655,9.82951954 19.1813221,9.65517448 19.6202597,9.65517448 C19.894763,9.65517448 20.1737225,9.65517448 20.4482258,9.65517448 C20.8871634,9.65517448 21.3078305,9.82951954 21.6184294,10.1395889 C21.9290279,10.4507617 22.1032662,10.871176 22.1032662,11.3103488 Z"
          id="Shape"
          fill={props.htmlColor || theme.colors.greyScale.white}
        ></path>
      </g>
    </SvgIcon>
  );
};
