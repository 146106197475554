import React from 'react';

import { styled, Avatar, Tooltip } from '@material-ui/core';

import {
  SdmTypography,
  SdmIconButton,
  MicrophoneActiveIcon,
  MicrophoneDisabledIcon,
  CameraActiveIcon,
  CameraDisabledIcon,
  HandWhite,
} from './../../../components';
import { theme } from './../../../theme';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

const FlexContainer = styled('div')({
  display: 'flex',
});

const NameContainer = styled('div')({
  display: 'flex',
  gap: 10,
});

const NameTypography = styled(SdmTypography)({
  font: 'normal normal 16px/20px Roboto',
  color: '#FFFFFF',
});

const CompanyTypography = styled(SdmTypography)({
  font: 'normal normal 12px/14px Roboto',
  color: '#84819A',
});

const CustomAvatar = styled(Avatar)({
  backgroundColor: theme.colors.primary.red,
  width: 56,
  height: 56,
  marginRight: 10,
});

const ParticipantCardRow = ({
  name,
  company,
  image,
  isVideoEnabled,
  isMicrophoneOpen,
  sendMicrophoneSignal,
  sendCameraSignal,
  onKick,
  onUnpublish,
  i18n,
  isHandRaised,
}) => {
  return (
    <div>
      <FlexContainer
        style={{
          justifyContent: 'space-between',
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 10,
        }}
      >
        <div style={{ width: '20%' }}>
          <CustomAvatar alt={name} src={image}>
            {name?.[0]}
          </CustomAvatar>
        </div>
        <div style={{ width: '50%' }}>
          <FlexContainer
            style={{
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <NameContainer>
              <NameTypography margin={0}>{name}</NameTypography>
              {isHandRaised ? <HandWhite style={{ fontSize: 15 }} /> : null}
            </NameContainer>
            <CompanyTypography margin={0}>{company}</CompanyTypography>
          </FlexContainer>
        </div>
        <div
          style={{
            width: '35%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {/* Kick user icon */}
          {onKick ? (
            <Tooltip title={i18n?.page.tooltips.kick.label}>
              <SdmIconButton small="true" variant="white" onClick={onKick}>
                <ExitToAppIcon />
              </SdmIconButton>
            </Tooltip>
          ) : null}
          {/* Kick user icon */}
          {onUnpublish ? (
            <Tooltip title={i18n?.page.tooltips.unpublish.label}>
              <SdmIconButton small="true" variant="white" onClick={onUnpublish}>
                <CancelPresentationIcon />
              </SdmIconButton>
            </Tooltip>
          ) : null}
          {/* Video enabled icon */}
          <Tooltip
            title={
              isVideoEnabled
                ? i18n?.page.tooltips.camera.disable.label
                : i18n?.page.tooltips.camera.enable.label
            }
          >
            <SdmIconButton
              small="true"
              variant="white"
              style={{ pointerEvents: sendCameraSignal ? 'auto' : 'none' }}
              onClick={sendCameraSignal}
            >
              {isVideoEnabled ? <CameraActiveIcon /> : <CameraDisabledIcon />}
            </SdmIconButton>
          </Tooltip>
          {/* Mute microphone icon*/}
          <Tooltip
            title={
              isMicrophoneOpen
                ? i18n?.page.tooltips.microphone.disable.label
                : i18n?.page.tooltips.microphone.enable.label
            }
          >
            <SdmIconButton
              small="true"
              variant="white"
              style={{ pointerEvents: sendMicrophoneSignal ? 'auto' : 'none' }}
              onClick={sendMicrophoneSignal}
            >
              {isMicrophoneOpen ? (
                <MicrophoneActiveIcon />
              ) : (
                <MicrophoneDisabledIcon />
              )}
            </SdmIconButton>
          </Tooltip>
        </div>
      </FlexContainer>
    </div>
  );
};
export { ParticipantCardRow };
