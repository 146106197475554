import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const SaloneDelMobileIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        ...props.style,
      }}
      viewBox="0 0 512 83.439"
    >
      <title>logo-SMM-milano</title>
      <g id="Livello_2" data-name="Livello 2">
        <g id="Livello_1-2" data-name="Livello 1">
          <rect
            fill="#e2001a"
            x="9.17"
            y="9.169"
            width="65.1"
            height="65.1"
            transform="translate(-11.752 16.784) rotate(-19.999)"
          />
          <path
            fill="#fff"
            d="M42.765,37.047a5.659,5.659,0,0,1-4.453-3.286c-.746-2.044.914-2.36,2.5-2.08a5.655,5.655,0,0,1,4.438,3.3c.747,2.05-.9,2.344-2.481,2.064m1.6,4.4c3.745.66,5.519-1.742,4-5.914s-5.415-7.6-9.158-8.264-5.536,1.764-4.017,5.937,5.431,7.583,9.175,8.241"
          />
          <path
            fill="#fff"
            d="M66.036,26.852l-3.012,3.589-4.242-11.65L61.8,15.2ZM57.19,14.411l3.014-3.59,1.062,2.918L58.253,17.33Z"
          />
          <path
            fill="#fff"
            d="M17.149,55.882l4.611.811,4.247,11.652-4.619-.813Zm4.084-.646-4.619-.817L15.552,51.5l4.619.815Z"
          />
          <polygon
            fill="#fff"
            points="48.432 47.833 51.446 44.242 34.647 41.28 31.633 44.873 48.432 47.833"
          />
          <path
            fill="#fff"
            d="M48.412,31.154l2.925-3.489.579,1.6.034-.044a10.324,10.324,0,0,1,2.187-5.373c3.07-3.658,4.347-1.122,4.959.559l2.721,7.478L58.8,35.473,56.75,29.84c-.577-1.6-1.024-3.382-2.674-1.417a4.59,4.59,0,0,0-.725,4.438l2.315,6.35L52.65,42.8Z"
          />
          <path
            fill="#fff"
            d="M48.759,57.767c-.966-.167-1.643-1.065-2.214-2.088a23.471,23.471,0,0,0-1.65-2.838c2.481.337,3.817,1.231,4.581,3.33.358.986.272,1.769-.717,1.6m-5.035-1.13a3.851,3.851,0,0,1-2.332-2.475c-.33-.905-.42-2.062.495-1.9.755.134,1.141.591,1.555,1.366,1.678,3.16,3.109,7.877,7.085,8.576,2.518.448,2.956-1.369,2.024-3.935a11.764,11.764,0,0,0-3.12-4.7,3.382,3.382,0,0,0,1.058-.054l-1.614-4.435a5.5,5.5,0,0,1-2.937-.033l-5.343-.94c-3.459-.612-2.971,2.36-1.811,5.549a15.715,15.715,0,0,0,2.4,4.427,7.312,7.312,0,0,0,4.035,2.676Z"
          />
          <path
            fill="#fff"
            d="M28.989,65.514c1.276,3.152,3.842,1.411,6.029-1.189,2.073-2.471,4.038-6.293,2.9-9.415-.353-.963-1.453-2.1-3.488-.353-1.519,1.266-3.614,3.475-4.017,2.371-.293-.8.479-1.824.977-2.417a2.546,2.546,0,0,1,1.064-.85c.321-.092.578.053.759.549L36,50.89c-1.213-2.905-3.591-1.131-5.614,1.279-1.808,2.157-3.925,5.773-2.9,8.577,1.148,3.144,3.548.38,5.665-1.277.588-.474,1.595-1.416,1.924-.516.332.919-.385,2.227-.972,2.926a2.525,2.525,0,0,1-1.262.926c-.411.067-.762-.125-.972-.713Z"
          />
          <path
            fill="#1d1d1b"
            d="M105.641,52.044h.928c.592,1.73,2.07,8.739,8.235,8.739a6.663,6.663,0,0,0,6.67-6.925c0-6.715-6.417-5.067-10.638-6.715-3.3-1.264-5.237-3.63-5.237-7.893,0-4.517,3.968-8.53,8.148-8.53a8.588,8.588,0,0,1,5.489,1.986l1.436-1.774h.845v7.642h-.845c-.591-1.942-1.815-6.84-6.967-6.84a5.908,5.908,0,0,0-5.572,5.7c0,5.32,5.869,4.181,9.626,5.489a8.925,8.925,0,0,1,6.249,8.531c0,5.783-3.589,10.343-9.2,10.343a9.512,9.512,0,0,1-6.418-2.575l-1.9,2.153h-.845Z"
          />
          <path
            fill="#1d1d1b"
            d="M136.211,50.987a13.669,13.669,0,0,0-3.125.337c-2.111.548-3.885,1.436-3.885,4.392,0,2.408.888,4.223,2.746,4.223,2.617,0,4.264-3.338,4.264-6.292Zm7.81,8.613a5.162,5.162,0,0,1-4.179,2.2c-3,0-3.5-2.912-3.59-4.18h-.082c-1.1,2.111-2.493,4.18-6.419,4.18a4.794,4.794,0,0,1-4.855-5.11c0-3.842,3.588-5.53,6.079-6.078a37.948,37.948,0,0,1,5.236-.635V44.4c0-1.773-.76-2.364-2.787-2.364-1.521,0-3.885.675-3.885,1.688,0,.676,1.057.97,1.057,2.154a2.213,2.213,0,0,1-2.112,2.279,2.327,2.327,0,0,1-2.2-2.449c0-2.66,3.8-4.686,7.853-4.686,4.138,0,6.376.887,6.376,3.884v12.5c0,1.351.3,2.533,1.31,2.533a2.421,2.421,0,0,0,1.562-.93Z"
          />
          <path
            fill="#1d1d1b"
            d="M144.19,60.023H147.1V32.494h-3.378V31.48h3.252a31.271,31.271,0,0,0,4.432-.337v28.88h3.126v1.013H144.19Z"
          />
          <path
            fill="#1d1d1b"
            d="M163.9,60.783c4.728,0,4.728-4.39,4.728-9.375s0-9.371-4.728-9.371-4.731,4.39-4.731,9.371,0,9.375,4.731,9.375m-9.712-9.375c0-5.065,3.968-10.385,9.712-10.385s9.711,5.32,9.711,10.385S169.643,61.8,163.9,61.8s-9.712-5.32-9.712-10.388"
          />
          <path
            fill="#1d1d1b"
            d="M173.654,60.023h2.913V42.8h-3.378V41.783h3.252a23.315,23.315,0,0,0,4.179-.337v5.405h.085c.844-2.027,2.618-5.828,7.052-5.828,5.572,0,5.868,3.42,5.868,6.628V60.023h3v1.013H186.532V60.023h2.787V48.454c0-3.674,0-6-2.746-6-2.871,0-5.7,4.306-5.7,7.98v9.585H184v1.013H173.654Z"
          />
          <path
            fill="#1d1d1b"
            d="M209.218,46.343c0-2.491-.928-4.306-3.631-4.306-4.433,0-4.519,3-4.519,6.923h8.15ZM214.158,55c-.76,2.238-2.365,6.8-8.361,6.8-5.7,0-9.71-4.814-9.71-10.261,0-4.94,4.306-10.512,9.543-10.512,5.319,0,8.232,4.644,8.232,8.95H201.068v3.8c0,2.787.043,7.009,4.646,7.009,4.9,0,7.093-4.307,7.6-6.081Z"
          />
          <path
            fill="#1d1d1b"
            d="M229.125,60.782c4.431,0,5.656-4.643,5.656-9.374,0-5.275-1.268-9.373-5.656-9.373-3.254,0-3.337,3.632-3.337,9.121,0,6.038.083,9.626,3.337,9.626m5.909-3.039h-.084c-.633,1.224-2.195,4.053-5.825,4.053-4.478,0-8.318-5.025-8.318-10.6,0-5.447,3.84-10.176,8.318-10.176a5.946,5.946,0,0,1,5.572,4.053h.084V32.494H231.4V31.48h3.252a31.123,31.123,0,0,0,4.432-.337V60.022h3.167v1.014h-2.829a30.64,30.64,0,0,0-4.391.337Z"
          />
          <path
            fill="#1d1d1b"
            d="M255.386,46.343c0-2.491-.929-4.306-3.631-4.306-4.434,0-4.519,3-4.519,6.923h8.15ZM260.325,55c-.758,2.238-2.363,6.8-8.36,6.8-5.7,0-9.71-4.814-9.71-10.261,0-4.94,4.306-10.512,9.542-10.512,5.32,0,8.232,4.644,8.232,8.95H247.236v3.8c0,2.787.043,7.009,4.645,7.009,4.9,0,7.093-4.307,7.6-6.081Z"
          />
          <path
            fill="#1d1d1b"
            d="M260.285,60.023H263.2V32.494h-3.378V31.48h3.249a31.29,31.29,0,0,0,4.434-.337v28.88h3.126v1.013H260.285Z"
          />
          <polygon
            fill="#1d1d1b"
            points="276.516 60.023 280.232 60.023 280.232 32.494 276.516 32.494 276.516 31.48 285.679 31.48 292.603 52.929 292.687 52.929 299.441 31.48 308.521 31.48 308.521 32.494 305.057 32.494 305.057 60.023 308.562 60.023 308.562 61.036 296.573 61.036 296.573 60.023 300.076 60.023 300.076 33.971 299.991 33.971 291.377 61.036 290.407 61.036 281.583 33.549 281.499 33.549 281.499 60.023 285.214 60.023 285.214 61.036 276.516 61.036 276.516 60.023"
          />
          <path
            fill="#1d1d1b"
            d="M317.98,60.783c4.727,0,4.727-4.39,4.727-9.375s0-9.371-4.727-9.371-4.729,4.39-4.729,9.371,0,9.375,4.729,9.375m-9.712-9.375c0-5.065,3.968-10.385,9.712-10.385s9.71,5.32,9.71,10.385S323.721,61.8,317.98,61.8s-9.712-5.32-9.712-10.388"
          />
          <path
            fill="#1d1d1b"
            d="M340.459,60.783c3.252,0,3.333-3.589,3.333-9.627,0-5.489-.081-9.119-3.333-9.119-4.391,0-5.659,4.094-5.659,9.371,0,4.73,1.224,9.375,5.659,9.375m-13.554-.76h3.589V32.494h-3.377V31.48h3.25a31.3,31.3,0,0,0,4.433-.337V45.076h.085a5.945,5.945,0,0,1,5.574-4.053c4.476,0,8.318,4.729,8.318,10.174,0,5.574-3.842,10.6-8.318,10.6-3.632,0-5.195-2.828-5.828-4.053h-.085v3.631c-.633-.086-1.266-.169-1.983-.253-.718-.043-1.478-.085-2.406-.085H326.9Z"
          />
          <path
            fill="#1d1d1b"
            d="M351.345,35.2a2.535,2.535,0,1,1,2.537,2.531,2.544,2.544,0,0,1-2.537-2.531m-2.406,24.826h2.914V42.8h-3.376V41.783h3.251a31.3,31.3,0,0,0,4.433-.337V60.023h3.123v1.013H348.939Z"
          />
          <path
            fill="#1d1d1b"
            d="M358.441,60.023h2.912V32.494h-3.376V31.48h3.251a31.3,31.3,0,0,0,4.433-.337v28.88h3.124v1.013H358.441Z"
          />
          <path
            fill="#1d1d1b"
            d="M381.536,46.343c0-2.491-.928-4.306-3.63-4.306-4.435,0-4.519,3-4.519,6.923h8.149ZM386.477,55c-.761,2.238-2.366,6.8-8.361,6.8-5.7,0-9.711-4.814-9.711-10.261,0-4.94,4.306-10.512,9.543-10.512,5.32,0,8.232,4.644,8.232,8.95H373.387v3.8c0,2.787.042,7.009,4.646,7.009,4.9,0,7.092-4.307,7.6-6.081Z"
          />
          <path
            fill="#1d1d1b"
            d="M387.533,59.178a2.66,2.66,0,1,1,2.66,2.618,2.587,2.587,0,0,1-2.66-2.618"
          />
          <polygon
            fill="#1d1d1b"
            points="398.487 60.023 402.202 60.023 402.202 32.494 398.487 32.494 398.487 31.48 407.65 31.48 414.574 52.929 414.658 52.929 421.414 31.48 430.491 31.48 430.491 32.494 427.03 32.494 427.03 60.023 430.535 60.023 430.535 61.036 418.542 61.036 418.542 60.023 422.047 60.023 422.047 33.971 421.962 33.971 413.349 61.036 412.379 61.036 403.553 33.549 403.468 33.549 403.468 60.023 407.186 60.023 407.186 61.036 398.487 61.036 398.487 60.023"
          />
          <path
            fill="#1d1d1b"
            d="M433.449,35.2a2.532,2.532,0,1,1,2.533,2.531,2.541,2.541,0,0,1-2.533-2.531m-2.408,24.826h2.914V42.8h-3.378V41.783h3.251a31.275,31.275,0,0,0,4.433-.337V60.023h3.124v1.013H431.041Z"
          />
          <path
            fill="#1d1d1b"
            d="M440.372,60.023h2.914V32.494h-3.378V31.48h3.251a31.278,31.278,0,0,0,4.434-.337v28.88h3.124v1.013H440.372Z"
          />
          <path
            fill="#1d1d1b"
            d="M462.357,50.987a13.679,13.679,0,0,0-3.124.337c-2.111.548-3.885,1.436-3.885,4.392,0,2.408.889,4.223,2.746,4.223,2.617,0,4.263-3.338,4.263-6.292Zm7.811,8.613a5.157,5.157,0,0,1-4.179,2.2c-3,0-3.5-2.912-3.589-4.18h-.085c-1.1,2.111-2.491,4.18-6.417,4.18a4.793,4.793,0,0,1-4.854-5.11c0-3.842,3.587-5.53,6.078-6.078a37.954,37.954,0,0,1,5.235-.635V44.4c0-1.773-.758-2.364-2.787-2.364-1.52,0-3.884.675-3.884,1.688,0,.676,1.057.97,1.057,2.154a2.213,2.213,0,0,1-2.112,2.279,2.327,2.327,0,0,1-2.2-2.449c0-2.66,3.8-4.686,7.853-4.686,4.138,0,6.377.887,6.377,3.884v12.5c0,1.351.3,2.533,1.308,2.533a2.426,2.426,0,0,0,1.563-.93Z"
          />
          <path
            fill="#1d1d1b"
            d="M470.182,60.023H473.1V42.8h-3.38V41.783h3.253a23.335,23.335,0,0,0,4.18-.337v5.405h.085c.843-2.027,2.617-5.828,7.052-5.828,5.57,0,5.866,3.42,5.866,6.628V60.023h3v1.013H483.06V60.023h2.785V48.454c0-3.674,0-6-2.745-6-2.871,0-5.7,4.306-5.7,7.98v9.585h3.124v1.013H470.182Z"
          />
          <path
            fill="#1d1d1b"
            d="M502.288,60.783c4.727,0,4.727-4.39,4.727-9.375s0-9.371-4.727-9.371-4.729,4.39-4.729,9.371,0,9.375,4.729,9.375m-9.71-9.375c0-5.065,3.967-10.385,9.71-10.385S512,46.343,512,51.408,508.031,61.8,502.288,61.8s-9.71-5.32-9.71-10.388"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
