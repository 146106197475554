import React from 'react';

import { styled } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const CustomRadioButton = styled(Radio)({});

// eslint-disable-next-line unused-imports/no-unused-vars
export const SdmRadioButton = ({ children, style, ...rest }) => (
  <CustomRadioButton {...rest}>{children}</CustomRadioButton>
);
