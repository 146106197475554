import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import axios from 'axios';

import { aws } from './../utils';
import { useLocation } from 'react-router-dom';

const cookieName = 'sdm-auth';
const lsName = 'sdm-location';

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [authCookie, setAuthCookie] = useState(Cookies.get(cookieName));
  const [user, setUser] = useState();
  const [isParamsCheck, setIsParamsCheck] = useState();
  const location = useLocation();

  const execLambda = async () => {
    const response = await aws.standardAPI
      .get('salesforceauth', '/oauth2/auth', {
        queryStringParameters: {
          sender: window.location.origin,
        },
      })
      .catch(console.error);

    if (!response) return console.error('fail /oauth2/auth');

    window.location = response.authUrl;
  };

  const refreshToken = async () => {
    try {
      const currentUser = JSON.parse(Cookies.get('sdm-auth') || '{}');
      const res = await aws.standardAPI.post(
        'salesforceauth',
        '/oauth2/refresh',
        {
          body: currentUser,
        }
      );
      Cookies.set(cookieName, JSON.stringify({ ...currentUser, ...res }), {
        expires: 7,
      });
      const cookie = Cookies.get(cookieName);
      setAuthCookie(cookie);
    } catch (e) {
      console.error('refreshToken', e);
      setAuthCookie();
    }
  };

  useEffect(() => {
    if (authCookie) setUser(JSON.parse(authCookie));
  }, [authCookie]);

  useEffect(() => {
    const checkParams = async () => {
      if (location.search) {
        const params = new URLSearchParams(location.search);
        const currentCookie = Cookies.get(cookieName);
        if (location.pathname === '/user') {
          const userData = {
            id: params.get('id'),
            Email: params.get('Email'),
            FirstName: params.get('FirstName'),
            LastName: params.get('LastName'),
            Tipologia: params.get('Tipologia'),
            accessToken: params.get('accessToken'),
            refreshToken: params.get('refreshToken'),
            issuedAt: params.get('issuedAt'),
            picture: params.get('picture'),
            thumbnail: params.get('thumbnail'),
          };
          Cookies.set(cookieName, JSON.stringify(userData), { expires: 7 });
          const cookie = Cookies.get(cookieName);

          setAuthCookie(cookie);
        } else if (
          currentCookie &&
          params.get('email') &&
          JSON.parse(currentCookie).Email !== params.get('email')
        ) {
          setAuthCookie();
        }
      }
      setIsParamsCheck(true);
    };

    checkParams();
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (!isParamsCheck) return;

    const checkCookies = async () => {
      if (!authCookie) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.delete('email');
        window.localStorage.setItem(
          lsName,
          `${window.location.origin}${
            window.location.pathname
          }?${params.toString()}`
        );
        await execLambda();
        return;
      }

      const route = window.localStorage.getItem(lsName);
      if (route) {
        window.location = route;
        window.localStorage.removeItem(lsName);
      }
    };

    checkCookies();
  }, [authCookie, isParamsCheck]);

  axios.interceptors.response.use(null, (err) => {
    if (err?.response?.data?.errors?.[0]?.errorCode === '10') {
      refreshToken();
    }
    return Promise.reject(err);
  });

  return (
    <AuthContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };
