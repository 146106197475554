import React, { useRef } from 'react';

import Draggable from 'react-draggable';

import { useIntl } from 'react-intl';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

import { theme } from '../../theme';
import { translation } from './../../utils';

import { styled } from '@material-ui/core/styles';

import {
  SdmTypography,
  MicrophoneActiveIcon,
  MicrophoneDisabledIcon,
  ScreenShareActiveIcon,
} from './../../components';

const MainContainer = styled('div')(({ isMobile }) => ({
  width: isMobile ? 150 : 300,
  height: isMobile ? 100 : 200,
  margin: 10,
  position: 'absolute',
  zIndex: 1202,
  bottom: 60,
  right: 10,
  cursor: 'all-scroll',
}));

const InnerContainer = styled('div')({
  position: 'relative',
});

const ControlsContainer = styled('div')({
  position: 'absolute',
  bottom: 10,
  right: 10,
  zIndex: 22,
});

const TextContainer = styled('div')({
  position: 'absolute',
  bottom: 15,
  left: 10,
  zIndex: 22,
});

const TextContainerInnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledText = styled(SdmTypography)({
  color: theme.colors.greyScale.white,
  marginLeft: 8,
});

const Video = styled('div')(({ isMobile }) => ({
  width: isMobile ? 150 : 300,
  height: isMobile ? 100 : 200,
  zIndex: 21,
}));

const ShareContent = styled('div')({
  width: '100%',
  height: '100%',
  background: `#202124`,
  color: `${theme.colors.greyScale.white}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const PublisherDraggableControls = ({
  publisher,
  publisherDivName,
  publisherScreen,
  isMicrophoneOpen,
}) => {
  // Hooks
  const intl = useIntl();
  const i18n = translation.breakoutRoom.publisherDraggableControls(intl);
  const MUITheme = useTheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down('sm'));

  // Refs
  const nodeRef = useRef(null);

  return (
    <Draggable bounds="body" nodeRef={nodeRef}>
      <MainContainer
        ref={nodeRef}
        style={{ pointerEvents: publisher ? 'auto' : 'none' }}
        isMobile={isMobile}
      >
        <InnerContainer>
          <Video style={{ display: publisherScreen ? 'block' : 'none' }}>
            <ShareContent>
              <div>
                <ScreenShareActiveIcon />
              </div>
              <div>
                <SdmTypography
                  style={{ fontFamily: 'FSSiena-Regular', fontSize: '1.2rem' }}
                >
                  {i18n.presenting.label}
                </SdmTypography>
              </div>
            </ShareContent>
          </Video>
          <Video
            id={`${publisherDivName}`}
            style={{ display: publisherScreen ? 'none' : 'block' }}
            isMobile={isMobile}
          />

          {publisher && (
            <TextContainer>
              <TextContainerInnerContainer>
                {isMicrophoneOpen ? (
                  <MicrophoneActiveIcon
                    style={{ fontSize: 30 }}
                    htmlColor={theme.colors.greyScale.white}
                  />
                ) : (
                  <MicrophoneDisabledIcon
                    style={{ fontSize: 30 }}
                    htmlColor={theme.colors.greyScale.white}
                  />
                )}

                <StyledText variant="caption" margin={0}>
                  {i18n.you.label}
                </StyledText>
              </TextContainerInnerContainer>
            </TextContainer>
          )}
          <ControlsContainer></ControlsContainer>
        </InnerContainer>
      </MainContainer>
    </Draggable>
  );
};
