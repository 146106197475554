import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Layout1 = (props) => (
  <SvgIcon
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    style={{
      transform: 'rotate(90deg)',
      fontSize: '2rem',
      ...props.style,
    }}
  >
    <g
      id="Ui/Elements/Icons/Layout/Bottom_Bar/Black"
      style={{
        stroke: 'none',
        strokeWidth: '1',
        fill: 'none',
        fillRule: 'evenodd',
      }}
    >
      <path
        d="M28.3342175,8 L11.5278515,8 C10.6840424,8 10,8.82655123 10,9.84615385 L10,30.1538462 C10,31.1734488 10.6840424,32 11.5278515,32 L28.3342175,32 C29.1780266,32 29.862069,31.1734488 29.862069,30.1538462 L29.862069,9.84615385 C29.862069,8.82655123 29.1780266,8 28.3342175,8 Z M12.6551724,9.65517241 L22.4137931,9.65517241 L22.4137931,9.65517241 L22.4137931,30.3448276 L12.6551724,30.3448276 C12.1028877,30.3448276 11.6551724,29.8971123 11.6551724,29.3448276 L11.6551724,10.6551724 C11.6551724,10.1028877 12.1028877,9.65517241 12.6551724,9.65517241 Z M24.0689655,30.3448276 L24.0689655,9.65517241 L27.2068966,9.65517241 C27.7591813,9.65517241 28.2068966,10.1028877 28.2068966,10.6551724 L28.2068966,29.3448276 C28.2068966,29.8971123 27.7591813,30.3448276 27.2068966,30.3448276 L24.0689655,30.3448276 L24.0689655,30.3448276 Z"
        id="Shape"
        style={{
          fill: '#000000',
          fillRule: 'nonzero',
        }}
      ></path>
    </g>
  </SvgIcon>
);

export { Layout1 };
