import React, { useContext } from 'react';

import { useIntl } from 'react-intl';

import { translation } from './../../../utils';

import { AuthContext } from './../../../contexts';

import { ParticipantCardRow } from './ParticipantCardRow';
import { SdmTypography } from './../../../components';
import { theme } from './../../../theme';

import { styled } from '@material-ui/core/styles';
import {
  MicrophoneDisabledIcon,
  SdmIconAndTextButton,
  ScreenShareStopIcon,
} from '../../../components';

const MainContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - (120px + 45px))',
  paddingTop: 20,
});

const TopButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 10,
  paddingRight: 10,
});

const TabParticipants = ({
  publisherData,
  isMicrophoneOpen,
  unpublishUser,
  handleMute,
  isVideoEnabled,
  toggleVideo,
  subscribers,
  sendSignal,
  isModerator,
  kickUser,
  handsRaised,
  muteAll,
  disableAllScreenShared,
}) => {
  //Hooks
  const intl = useIntl();
  const i18n = translation.breakoutRoom.tabParticipants(intl);

  // Contexts
  const { user } = useContext(AuthContext);

  return (
    <MainContainer>
      {/* Tolto perchè non c'era da grafiche */}
      {/* <div
        style={{
          marginLeft: 10,
          marginBottom: 16,
        }}
      >
        <SdmTypography
          margin={0}
          style={{
            fontSize: '1rem',
            fontFamily: 'FSSiena-Regular',
          }}
        >
          {i18n.page.userActive.label} ({(subscribers?.length || 0) + 1})
        </SdmTypography>
      </div> */}

      <TopButtonContainer>
        {isModerator ? (
          <SdmIconAndTextButton
            variant="blackTissueFill"
            text={
              <SdmTypography style={{ color: theme.colors.greyScale.white }}>
                {i18n.page.muteAll.label}
              </SdmTypography>
            }
            onClick={muteAll}
          >
            <MicrophoneDisabledIcon />
          </SdmIconAndTextButton>
        ) : null}
        {isModerator ? (
          <SdmIconAndTextButton
            variant="blackTissueFill"
            text={
              <SdmTypography style={{ color: theme.colors.greyScale.white }}>
                {i18n.page.disableAllScreenSharing.label}
              </SdmTypography>
            }
            onClick={disableAllScreenShared}
          >
            <ScreenShareStopIcon />
          </SdmIconAndTextButton>
        ) : null}
      </TopButtonContainer>

      {publisherData ? (
        <ParticipantCardRow
          name={`${publisherData?.FirstName} ${publisherData?.LastName}`}
          company={''} //TODO
          image={user?.picture}
          isMicrophoneOpen={isMicrophoneOpen}
          isVideoEnabled={isVideoEnabled}
          sendMicrophoneSignal={handleMute}
          sendCameraSignal={toggleVideo}
          i18n={i18n}
          isHandRaised={handsRaised.find((h) => h === publisherData?.id)}
        />
      ) : null}
      {subscribers?.map((s, idx) => {
        const subscriberData = JSON.parse(s?.stream?.connection?.data);
        const isHandRaised = handsRaised.find((h) => h === subscriberData?.id);

        return (
          <ParticipantCardRow
            i18n={i18n}
            key={idx}
            company={''} //TODO
            name={`${subscriberData?.FirstName} ${subscriberData?.LastName}`}
            image={subscriberData?.picture}
            isMicrophoneOpen={s?.stream?.hasAudio}
            isVideoEnabled={s?.stream?.hasVideo}
            onKick={isModerator && kickUser ? () => kickUser(s) : null}
            onUnpublish={
              isModerator && unpublishUser ? () => unpublishUser(s) : null
            }
            sendMicrophoneSignal={
              isModerator && sendSignal
                ? () => sendSignal(s, 'microphone')
                : null
            }
            sendCameraSignal={
              isModerator && sendSignal ? () => sendSignal(s, 'camera') : null
            }
            isHandRaised={isHandRaised}
          />
        );
      })}
    </MainContainer>
  );
};

export { TabParticipants };
