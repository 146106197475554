import React from 'react';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useIntl } from 'react-intl';

import {
  styled,
  Tooltip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

import { theme } from '../../../theme';
import { Layout2, Layout1, Options } from '../../../components';

import { translation } from './../../../utils';

const StyledMenuItem = styled(MenuItem)(({ disabled, isSelected }) => ({
  borderBottom: disabled
    ? `1px solid ${theme.colors.greyScale.darkGray}`
    : 'none',
  backgroundColor: isSelected
    ? theme.colors.greyScale.grey
    : theme.colors.greyScale.white,
  '&:hover': {
    backgroundColor: isSelected
      ? theme.colors.greyScale.grey
      : theme.colors.greyScale.white,
  },
  opacity: `1 !important`,
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontSize: disabled ? 20 : 16,
    },
  },
}));

const StyledMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    borderRadius: 0,
    width: 200,
    webkitBoxShadow: '0 0 10px 1px black',
    mozBoxShadow: '0 0 10px 1px black',
    boxShadow: '0 0 10px 1px black',
  },
});

const LayoutMenu = ({ selectedLayout, setSelectedLayout }) => {
  //Hooks
  const intl = useIntl();
  const i18n = translation.breakoutRoom.layoutMenu(intl);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Tooltip title={i18n.menu.tooltip.label}>
            <div
              style={{ margin: 'auto', cursor: 'pointer' }}
              {...bindTrigger(popupState)}
            >
              <Options htmlColor="black" />
            </div>
          </Tooltip>
          <StyledMenu
            {...bindMenu(popupState)}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <StyledMenuItem disabled>
              <ListItemText primary={i18n.menu.title.label} />
            </StyledMenuItem>
            <StyledMenuItem
              isSelected={selectedLayout === 2}
              onClick={() => setSelectedLayout(2)}
            >
              <ListItemIcon>
                <Layout2 />
              </ListItemIcon>
              <ListItemText primary={i18n.menu.options.layout1.label} />
            </StyledMenuItem>
            <StyledMenuItem
              isSelected={selectedLayout === 1}
              onClick={() => setSelectedLayout(1)}
            >
              <ListItemIcon>
                <Layout1 />
              </ListItemIcon>
              <ListItemText primary={i18n.menu.options.layout2.label} />
            </StyledMenuItem>
          </StyledMenu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export { LayoutMenu };
