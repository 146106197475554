import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ArrowBackIcon = (props) => (
  <SvgIcon
    {...props}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    style={{
      fontSize: 40,
      ...props.style,
    }}
  >
    <g
      id="Ui/Elements/Icons/Cta/Cta-Arrow/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M20.0007471,38 C20.7371844,38 21.3341755,37.3800705 21.3342124,36.6153841 L21.3342124,6.72744546 L29.725669,15.4404943 C30.2466526,15.9794055 31.0894814,15.9785727 31.6094764,15.4386331 C32.1294714,14.8986934 32.1302763,14.0235343 31.6112755,13.4825643 L20.9444005,2.406523 C20.8814663,2.34136644 20.8134351,2.28360713 20.7404403,2.23319678 C20.707397,2.21025369 20.6703267,2.19376984 20.6352432,2.17391444 C20.5949869,2.14903072 20.5535039,2.12635199 20.5109643,2.10597134 C20.4660772,2.08869905 20.4203237,2.07395908 20.373904,2.06181594 C20.336507,2.05048978 20.3000434,2.0351967 20.261993,2.027339 C20.0895913,1.990887 19.9118829,1.990887 19.7394813,2.027339 C19.7013108,2.03523824 19.6646872,2.05057978 19.6271635,2.0619544 C19.5809208,2.07408714 19.5353338,2.08877106 19.4905967,2.10594365 C19.4478154,2.12638689 19.4061123,2.14917991 19.3656643,2.17422598 C19.3308275,2.1939983 19.2939239,2.21039907 19.2610406,2.23321754 C19.1880459,2.2836279 19.1200147,2.34138721 19.0579339,2.40565761 L8.39022541,13.4825643 C7.8698091,14.0232834 7.86994183,14.8996442 8.39052191,15.4401933 C8.91110199,15.9807424 9.75508812,15.9808803 10.2758319,15.4405012 L18.6672819,6.72744546 L18.6672819,36.6153841 C18.6673187,37.3800705 19.2643099,38 20.0007471,38 Z"
        id="Path-Copy-3"
        fill="#FFFFFF"
        fillRule="nonzero"
        transform="translate(20.000000, 20.000000) scale(-1, 1) rotate(90.000000) translate(-20.000000, -20.000000) "
      ></path>
    </g>
  </SvgIcon>
);
