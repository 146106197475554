import aws from './aws';
const { API } = aws;

const createSessionReaction = /* GraphQL */ `
  mutation CreateSessionReaction(
    $input: CreateSessionReactionInput!
    $condition: ModelSessionReactionConditionInput
  ) {
    createSessionReaction(input: $input, condition: $condition) {
      id
    }
  }
`;

export const useReactions = ({ showLoader, hideLoader }) => {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader?.();

      API.post(createSessionReaction, { input })
        .then((response) => {
          resolve(response.data.createSessionReaction);
        })
        .catch((e) => {
          console.error('create-reaction', e);
          reject();
        })
        .finally(() => useLoader && hideLoader?.());
    });
  return { create };
};
