import React from 'react';

import { Countdown } from './Countdown';
import { TopControls } from './TopControls';

const CountdownOverlay = ({ initialRemainingTime, totalTime, onComplete }) => {
  return (
    <>
      <TopControls hideWatching />
      <Countdown
        totalTime={totalTime}
        initialRemainingTime={initialRemainingTime}
        onComplete={onComplete}
      />
    </>
  );
};

export { CountdownOverlay };
