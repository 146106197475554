import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const Participants = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
    >
      <g
        id="Ui/Elements/Icons/People/Black"
        style={{
          stroke: 'none',
          strokeWidth: '1',
          fill: 'none',
          fillRule: 'evenodd',
        }}
      >
        <path
          d="M23.5011136,16.5011136 C23.5011136,18.4253898 21.9510022,20.0022272 20,20.0022272 C18.0489978,20.0022272 16.4988864,18.4253898 16.4988864,16.5011136 C16.4988864,14.5768374 18.0757238,13 20,13 C21.9242762,13 23.5011136,14.5501114 23.5011136,16.5011136 Z M25.55902,26.7639198 C24.7839644,23.3162584 22.5924276,20.830735 20,20.830735 C17.4075724,20.830735 15.2160356,23.3162584 14.44098,26.7639198 L25.55902,26.7639198 Z M12.9443207,13.8819599 C11.233853,13.8819599 9.844098,15.2717149 9.844098,16.9821826 C9.844098,18.6926503 11.233853,20.0824053 12.9443207,20.0824053 C14.6547884,20.0824053 16.0445434,18.6926503 16.0445434,16.9821826 C16.0445434,15.2717149 14.6547884,13.8819599 12.9443207,13.8819599 Z M27.0556793,13.8819599 C25.3452116,13.8819599 23.9554566,15.2717149 23.9554566,16.9821826 C23.9554566,18.6926503 25.3452116,20.0824053 27.0556793,20.0824053 C28.766147,20.0824053 30.1826281,18.7193764 30.1826281,17.0089087 C30.1826281,15.298441 28.7928731,13.8819599 27.0556793,13.8819599 Z M15.830735,22.1403118 C15.0022272,21.3385301 14.013363,20.857461 12.9443207,20.857461 C10.6458797,20.857461 8.69487751,23.0489978 8,26.1224944 L13.8262806,26.1224944 C14.2271715,24.518931 14.922049,23.155902 15.830735,22.1403118 Z M26.1469933,26.1224944 L32,26.1224944 C31.3051225,23.0757238 29.3541203,20.857461 27.0556793,20.857461 C25.9599109,20.857461 24.9710468,21.3385301 24.142539,22.1937639 C25.0512249,23.1826281 25.7461024,24.518931 26.1469933,26.1224944 Z"
          id="Shape"
          style={{
            fill: '#000000',
            fillRule: 'nonzero',
          }}
        ></path>
      </g>
    </SvgIcon>
  );
};
