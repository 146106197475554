import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { translation } from './../../utils';

import {
  SdmTypography,
  SdmCheckbox,
  SdmTextField,
  SdmIconAndTextButton,
} from './../../components';

import { theme } from './../../theme';
import { styled } from '@material-ui/core/styles';

const TransparentSdmTextField = styled(SdmTextField)({
  backgroundColor: 'transparent',
  borderBottom: `1px solid ${theme.colors.greyScale.white}`,
  borderRadius: 0,
  '& .MuiInput-input': {
    color: theme.colors.greyScale.white,
  },
});

const RoundSdmCheckbox = styled(SdmCheckbox)({
  '& .MuiSvgIcon-root': {
    borderRadius: 10,
  },
  '& .MuiIconButton-label': {
    height: '100%',
    background: theme.colors.greyScale.white,
  },
});

const formControlStyle = { width: 'calc(100%)' };

const AskAQuestion = ({ onSend }) => {
  //Hooks
  const intl = useIntl();
  const i18n = translation.webinar.askAQuestionDialog(intl);

  //State
  const [question, setQuestion] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  //Functions
  const handleQuestionChange = (data) => {
    setQuestion(data);
  };

  const toggleAnonymous = () => {
    setIsAnonymous(!isAnonymous);
  };

  const onSendQuestion = () => {
    const data = { question: question, isAnonymous: isAnonymous };
    onSend && onSend(data);
  };

  return (
    <Grid
      container
      style={{
        color: theme.colors.greyScale.white,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        width: '60%',
      }}
    >
      <Grid item xs={12}>
        <SdmTypography style={{ font: 'normal normal 300 54px/36px Roboto' }}>
          {i18n.text.title.label}
        </SdmTypography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '2%', paddingBottom: '1%' }}>
        <TransparentSdmTextField
          formControlStyle={formControlStyle}
          onChange={(e) => handleQuestionChange(e.target.value)}
          placeholder={i18n.text.inputPlaceHolder}
        />
      </Grid>
      <Grid item xs={12}>
        <SdmTypography style={{ font: 'normal normal 300 20px/36px Roboto' }}>
          {i18n.text.isAnonymousText.label}{' '}
          <RoundSdmCheckbox
            onClick={toggleAnonymous}
            checked={isAnonymous}
            variant="white"
          />
        </SdmTypography>
      </Grid>
      <Grid xs={12}>
        <SdmIconAndTextButton
          style={{ marginTop: 20, minWidth: 190, height: 45 }}
          variant="redFill"
          disabled={!question}
          text={
            <SdmTypography style={{ color: theme.colors.greyScale.white }}>
              {i18n.buttons.send.label}
            </SdmTypography>
          }
          onClick={onSendQuestion}
        />
      </Grid>
    </Grid>
  );
};

export { AskAQuestion };
