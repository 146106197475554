import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { theme } from './../../theme';

export const CameraDisabledIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
    >
      <g
        id="Ui/Elements/Icons/Camera/Disable/White"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M31.25,13.8045904 L31.25,25.6624208 L25.33,22.2445298 L25.33,24.9140364 C25.33,25.3991864 25.1415612,25.8402414 24.8338802,26.1680409 C24.5288262,26.4930418 24.1066938,26.7070493 23.6347581,26.7419519 L23.6347581,26.7419519 L16.2147607,26.7460092 L26.5361331,16.4255207 L31.25,13.8045904 Z M23.4970182,12.75 L9.95934637,26.638526 C9.67804693,26.5368242 9.42885549,26.3681005 9.23080281,26.1518908 C8.96188424,25.8583184 8.78725668,25.4768132 8.75532199,25.055305 L8.75532199,25.055305 L8.75,14.5826909 C8.75,14.0977281 8.93839648,13.6566503 9.24610037,13.3288159 C9.55099991,13.0039694 9.97292698,12.789992 10.4448393,12.7550727 L10.4448393,12.7550727 L23.4970182,12.75 Z"
          id="Combined-Shape"
          stroke={props.htmlColor || theme.colors.greyScale.white}
          strokeWidth="1.5"
          fillRule="nonzero"
        ></path>
        <rect
          id="Rectangle"
          stroke={props.htmlColor || theme.colors.greyScale.white}
          transform="translate(20.000000, 19.700000) rotate(-45.000000) translate(-20.000000, -19.700000) "
          x="6"
          y="19"
          width="28"
          height="1.4"
        ></rect>
      </g>
    </SvgIcon>
  );
};
