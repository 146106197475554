import React from 'react';

import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { minTwoDigits, translation } from './../../utils';

import { SdmTypography } from '../../components';
import { theme } from './../../theme';

const Countdown = ({ onComplete, initialRemainingTime, totalTime }) => {
  //Hooks
  const intl = useIntl();
  const i18n = translation.webinar.countdownComponent(intl);

  const parseTime = (remainingTime) => {
    const hours = minTwoDigits(Math.floor(remainingTime / 3600));
    const minutes = minTwoDigits(Math.floor((remainingTime % 3600) / 60));
    const seconds = minTwoDigits(remainingTime % 60);

    return (
      <Grid container>
        <Grid xs={12}>
          <SdmTypography
            textAlign="center"
            style={{
              font: 'normal normal normal 32px/38px Roboto',
              color: theme.colors.greyScale.white,
            }}
          >
            {i18n.title.label}
          </SdmTypography>
        </Grid>
        <Grid xs={12}>
          <SdmTypography
            textAlign="center"
            style={{
              font: 'normal normal bold 58px/59px Roboto',
              color: theme.colors.primary.red,
            }}
          >{`${hours}:${minutes}:${seconds}`}</SdmTypography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      style={{
        color: theme.colors.greyScale.white,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        width: 'unset',
      }}
    >
      <Grid item xs>
        <CountdownCircleTimer
          isPlaying
          duration={totalTime}
          strokeWidth={6}
          size={500}
          strokeLinecap="square"
          initialRemainingTime={initialRemainingTime}
          colors={theme.colors.primary.red}
          trailColor="#fff"
          onComplete={onComplete}
        >
          {({ remainingTime }) => parseTime(remainingTime)}
        </CountdownCircleTimer>
      </Grid>
    </Grid>
  );
};

export { Countdown };
