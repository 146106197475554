import React from 'react';

// import { differenceInSeconds } from 'date-fns';

import { useIntl } from 'react-intl';

import { styled, Tooltip, Hidden, useTheme } from '@material-ui/core';

import {
  SdmIconButton,
  CameraActiveIcon,
  CameraDisabledIcon,
  MicrophoneActiveIcon,
  MicrophoneDisabledIcon,
  ScreenShareActiveIcon,
  ScreenShareStopIcon,
  EndCallIcon,
  SaloneDelMobileIcon,
  HandBlack,
  HandBlackFilled,
} from '../../components';

import { TabSelector } from './tabs/TabSelector';

import { theme } from '../../theme';

import { LayoutMenu } from './bottomControls/LayoutMenu';

import { translation } from './../../utils';

/* const getSecondsFromNow = (date) => {
  return differenceInSeconds(date, new Date());
}; */

const LeftBottomControlsContainer = styled('div')(({ MUITheme }) => ({
  display: 'flex',
  marginLeft: 30,
  [MUITheme.breakpoints.down('sm')]: {
    marginLeft: 0,
    justifyContent: 'center',
  },
}));

const CenterBottomControlsContainer = styled('div')(({ MUITheme }) => ({
  display: 'flex',
  margin: 0,
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  [MUITheme.breakpoints.down('sm')]: {
    position: 'relative',
    left: 'unset',
    transform: 'unset',
    justifyContent: 'center',
  },
}));

const RightBottomControlsContainer = styled('div')(({ MUITheme }) => ({
  display: 'flex',
  marginRight: 15,
  [MUITheme.breakpoints.down('sm')]: {
    width: '100%',
    marginRight: 0,
  },
}));

const ControlContainer = styled('div')(({ MUITheme, bottomControlHeight }) => ({
  color: theme.colors.greyScale.black,
  backgroundColor: theme.colors.greyScale.white,
  width: '100%',
  height: bottomControlHeight,
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 1200,
  position: 'absolute',
  [MUITheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const BottomControlsComponent = ({
  navigateBack,
  selectedLayout,
  setSelectedLayout,
  onClickTabHeader,
  tabs,
  // selectedTab,
  bottomControlHeight,
  // end,
  handleMute,
  isMicrophoneOpen,
  toggleVideo,
  isVideoEnabled,
  isScreenShared,
  shareScreenFunction,
  publisherScreen,
  raiseHand,
  isHandRaised,
}) => {
  const MUITheme = useTheme();

  //Hooks
  const intl = useIntl();
  const i18n = translation.breakoutRoom.bottomControls(intl);

  /* // States
  const [secondsLeft, setSecondsLeft] = 
  State(0);

  // Effects
  useEffect(() => {
    if (end && setSecondsLeft) {
      const interval = setInterval(() => {
        setSecondsLeft(getSecondsFromNow(new Date(end)));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [end, setSecondsLeft]); */

  return (
    <ControlContainer
      MUITheme={MUITheme}
      bottomControlHeight={bottomControlHeight}
    >
      <LeftBottomControlsContainer MUITheme={MUITheme}>
        <SaloneDelMobileIcon style={{ height: 55, width: 250 }} />
      </LeftBottomControlsContainer>

      <CenterBottomControlsContainer MUITheme={MUITheme}>
        <Tooltip
          title={
            isVideoEnabled
              ? i18n.tooltips.camera.disable.label
              : i18n.tooltips.camera.enable.label
          }
        >
          {/* Disable self video */}
          <SdmIconButton variant="black" onClick={toggleVideo}>
            {isVideoEnabled ? (
              <CameraActiveIcon htmlColor={theme.colors.greyScale.black} />
            ) : (
              <CameraDisabledIcon htmlColor={theme.colors.greyScale.black} />
            )}
          </SdmIconButton>
        </Tooltip>

        {/* Disable mute microphone */}
        <Tooltip
          title={
            isMicrophoneOpen
              ? i18n.tooltips.microphone.disable.label
              : i18n.tooltips.microphone.enable.label
          }
        >
          <SdmIconButton variant="black" onClick={handleMute}>
            {isMicrophoneOpen ? (
              <MicrophoneActiveIcon htmlColor={theme.colors.greyScale.black} />
            ) : (
              <MicrophoneDisabledIcon
                htmlColor={theme.colors.greyScale.black}
              />
            )}
          </SdmIconButton>
        </Tooltip>

        {/* Disable screen sharing*/}
        <Hidden smDown>
          <Tooltip
            title={
              isScreenShared
                ? i18n.tooltips.videoSharing.someoneSharing.label
                : publisherScreen
                ? i18n.tooltips.videoSharing.disable.label
                : i18n.tooltips.videoSharing.enable.label
            }
          >
            <div>
              <SdmIconButton
                variant="black"
                onClick={shareScreenFunction}
                disabled={isScreenShared}
              >
                {publisherScreen ? (
                  <ScreenShareStopIcon
                    htmlColor={theme.colors.greyScale.black}
                  />
                ) : (
                  <ScreenShareActiveIcon
                    htmlColor={theme.colors.greyScale.black}
                  />
                )}
              </SdmIconButton>
            </div>
          </Tooltip>
        </Hidden>

        <Tooltip title={i18n.tooltips.exitCall.label}>
          <SdmIconButton variant="black" onClick={navigateBack}>
            <EndCallIcon onClick={navigateBack} />
          </SdmIconButton>
        </Tooltip>

        <Tooltip title={i18n.tooltips.raiseHand.label}>
          <SdmIconButton variant="black" onClick={raiseHand}>
            {isHandRaised ? (
              <HandBlackFilled style={{ fontSize: 30 }} />
            ) : (
              <HandBlack style={{ fontSize: 30 }} />
            )}
          </SdmIconButton>
        </Tooltip>
      </CenterBottomControlsContainer>

      <RightBottomControlsContainer MUITheme={MUITheme}>
        {tabs &&
          tabs.map(({ id, name, Icon }) => (
            <TabSelector
              key={id}
              name={name}
              Icon={Icon}
              onClickTabHeader={() => onClickTabHeader({ id, name })}
            />
          ))}
        <Hidden smDown>
          <LayoutMenu
            selectedLayout={selectedLayout}
            setSelectedLayout={setSelectedLayout}
          />
        </Hidden>
      </RightBottomControlsContainer>
    </ControlContainer>
  );
};

const BottomControls = React.memo(BottomControlsComponent);

export { BottomControls };
