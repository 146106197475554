import React from 'react';

import { theme } from '../../../theme';

import { styled, Tooltip } from '@material-ui/core';

const StyledTabSelector = styled('div')({
  color: theme.colors.greyScale.white,
  paddingRight: 25,
  cursor: 'pointer',
  margin: 'auto',
});

export const TabSelector = ({ Icon, name, onClickTabHeader }) => {
  return (
    <Tooltip title={name}>
      <StyledTabSelector onClick={onClickTabHeader}>
        <Icon htmlColor="black" />
      </StyledTabSelector>
    </Tooltip>
  );
};
