import React, { useState } from 'react';

import { SdmTypography, SdmCircularLoader } from './../../../../components';

import { styled } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { theme } from './../../../../theme';

import BlockIcon from '@material-ui/icons/Block';
import CloseIcon from '@material-ui/icons/Close';

const MainContainer = styled('div')({
  display: 'flex',
});

const Filler = styled('div')({
  flexGrow: 1,
});

const MessageContainer = styled('div')({
  marginTop: 8,
  maxWidth: '70%',
  minWidth: 100,
  minHeight: 55,
  borderRadius: 31,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const MessageTextContainer = styled('div')(({ paddingleft }) => ({
  paddingLeft: paddingleft ? paddingleft : 0,
  display: 'flex',
  flexDirection: 'column',
}));

const InnerContainer = styled('div')({
  display: 'flex',
  paddingLeft: 8,
  paddingRight: 8,
});

const MessageTypography = styled(SdmTypography)(
  ({ externalmargin, overrideopacity, overridefontsize }) => ({
    fontSize: overridefontsize ? overridefontsize : '0.850rem',
    margin: externalmargin != null ? externalmargin : 8,
    opacity: overrideopacity != null ? overrideopacity : 1,
    overflowWrap: 'anywhere',
  })
);

const Message = ({
  givenName,
  familyName,
  message,
  isMine,
  isModerator = false,
  isModerated = false,
  youTranslation,
  onBan,
  onUnban,
  onDelete,
  i18n,
  bannedUser = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBan, setIsLoadingBan] = useState(false);

  const handleUnban = () => {
    onUnban && onUnban();
  };

  const handleBan = async () => {
    if (onBan) {
      setIsLoadingBan(true);
      const res = await onBan();
      if (res) {
        setIsLoadingBan(false);
      }
    }
  };

  const handleDelete = async () => {
    if (onDelete) {
      setIsLoading(true);
      const res = await onDelete(i18n.moderator.deleteMessage);
      if (res) {
        setIsLoading(false);
      }
    }
  };
  return (
    <MainContainer>
      {isMine ? <Filler /> : null}
      <MessageContainer>
        {isMine ? <Filler /> : null}
        <InnerContainer>
          <MessageTextContainer paddingleft={!isMine ? 45 : 0}>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                display: 'flex',
              }}
            >
              <MessageTypography
                margin={0}
                overrideopacity={0.7}
                overridefontsize="0.75rem"
              >
                {isMine ? youTranslation : `${givenName} ${familyName}`}
              </MessageTypography>
              {!isMine && isModerator ? (
                isLoadingBan ? (
                  <SdmCircularLoader
                    style={{
                      marginTop: '-4px',
                      transform: 'scale(0.55)',
                    }}
                  />
                ) : bannedUser ? (
                  <SdmTypography
                    style={{
                      marginTop: '-2px',
                      marginLeft: '-8px',
                      color: theme.colors.primary.red,
                    }}
                    variant={'textBold'}
                  >
                    {i18n.moderator.buttons.bannedUser}
                  </SdmTypography>
                ) : (
                  <Tooltip title={i18n.moderator.buttons.ban}>
                    <BlockIcon
                      disabled={bannedUser ? true : false}
                      onClick={bannedUser ? () => {} : handleBan}
                      style={{
                        marginTop: '4px',
                        transform: 'scale(0.85)',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )
              ) : null}
            </div>
            <div style={{ display: 'flex' }}>
              {!isMine && isModerator ? (
                isLoading ? (
                  <SdmCircularLoader
                    style={{
                      marginTop: '-16px',
                      transform: 'scale(0.55)',
                    }}
                  />
                ) : (
                  <Tooltip
                    title={
                      isModerated
                        ? i18n.moderator.buttons.moderated
                        : i18n.moderator.buttons.delete
                    }
                  >
                    <CloseIcon
                      disabled={isModerated}
                      onClick={isModerated ? () => {} : handleDelete}
                      style={{
                        marginTop: '-9px',
                        cursor: isModerated ? 'arrow' : 'pointer',
                        color: isModerated ? theme.colors.primary.red : 'white',
                      }}
                    />
                  </Tooltip>
                )
              ) : null}
              <MessageTypography
                margin={0}
                externalmargin={isMine ? 8 : '-8px 8px 8px 8px'}
                style={{
                  color: isModerated ? theme.colors.primary.red : 'white',
                }}
              >
                {message}
              </MessageTypography>
            </div>
          </MessageTextContainer>
        </InnerContainer>
        {!isMine ? <Filler /> : null}
      </MessageContainer>
    </MainContainer>
  );
};

export { Message };
