import React from 'react';

import { SdmTypography } from './../../components';
import { theme } from './../../theme';

const SurveyCard = ({ item, onSelect, selected }) => {
  //Styles
  const selectedStyle = {
    background: theme.colors.greyScale.white,
    color: theme.colors.greyScale.black,
  };

  const notSelectedStyle = {
    background: 'transparent',
    border: `2px solid ${theme.colors.greyScale.white}`,
  };

  const currentStyle =
    selected?.id === item?.id ? selectedStyle : notSelectedStyle;

  return (
    <div
      style={{
        height: 67,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ...currentStyle,
      }}
      onClick={() => onSelect(item)}
    >
      <SdmTypography
        textAlign="center"
        style={{
          font: 'normal normal normal 24px/25px Roboto',
        }}
      >
        {item.text}
      </SdmTypography>
    </div>
  );
};

export { SurveyCard };
