import React from 'react';

import { useIntl } from 'react-intl';

import { Boop } from './pleaseRotate/Boop';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

import { styled } from '@material-ui/core/styles';

import { SdmTypography } from './../components';

import { translation } from './../utils';

const MainContainer = styled('div')({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const MainTextTypography = styled(SdmTypography)({
  marginLeft: '10%',
  marginRight: '10%',
});

const PleaseRotate = () => {
  //Hooks
  const intl = useIntl();
  const i18n = translation.webinar.pleaseRotate(intl);

  return (
    <MainContainer>
      <Boop rotation={90} timing={1500}>
        <PhoneAndroidIcon style={{ fontSize: '5rem' }} />
      </Boop>
      <MainTextTypography variant="h4regular" textAlign="center" style={{}}>
        {i18n.mainText.label}
      </MainTextTypography>
    </MainContainer>
  );
};

export { PleaseRotate };
