import React from 'react';

import { theme } from '../../theme';
import { styled } from '@material-ui/core/styles';

import { SdmImage } from '../../components';

const ImageWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  overflow: 'hidden',
});

const StyledImage = styled(SdmImage)({
  objectFit: 'cover',
  width: '100vw',
  height: '100vh',
});

const ImageOverlay = styled('div')(({ overlayOpacity }) => ({
  position: 'absolute',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.colors.greyScale.black,
  opacity: overlayOpacity ? overlayOpacity : 0.8,
  overflow: 'hidden',
  top: 0,
  left: 0,
}));

const ImageBackground = ({
  src,
  children,
  overlayOpacity,
  showShadow,
  ...rest
}) => {
  return (
    <>
      <ImageWrapper>
        <StyledImage alt="background-image" src={src} {...{ ...rest }} />
        {children}
      </ImageWrapper>
      {showShadow ? <ImageOverlay overlayOpacity={overlayOpacity} /> : null}
    </>
  );
};

export { ImageBackground };
