/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://mwnln6rwb5ga7askdu6eeauz5y.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "virtualEventInterface",
            "endpoint": "https://ebmbkv89yb.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "salesforceauth",
            "endpoint": "https://p20p29drgd.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "authendpoint",
            "endpoint": "https://0tcuzhl2cg.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "vonageServer",
            "endpoint": "https://o8o9jt4n11.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "sdmPubNub",
            "endpoint": "https://kq40afuwag.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "mobile",
            "endpoint": "https://tsl3s9bjxd.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "webinarInteraction",
            "endpoint": "https://euivi4pi4j.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "drupalProxy",
            "endpoint": "https://izbni3xtq1.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "sdmVirtualEventModeratorApi",
            "endpoint": "https://1r4sodtaud.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "sdmBrSettingsApi",
            "endpoint": "https://hfshja79x2.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "sdmStripeApi",
            "endpoint": "https://bfi4o1o4r5.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "sdmAgendaApi",
            "endpoint": "https://nh3jkrknol.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "sdmAppointmentApi",
            "endpoint": "https://p9ntn1tm03.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        },
        {
            "name": "sdmAzureApi",
            "endpoint": "https://hzh2dgp2ke.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
