const AgendaSessionLiveReactions = {
  ThumbsUp: { id: 'thumbsup', emoji: ['👍'] },
  Smile: { id: 'smile', emoji: ['😄'] },
  Heart: { id: 'heart', emoji: ['🧡'] },
  Applause: { id: 'applause', emoji: ['👏'] },
};

export default {
  AgendaSessionLiveReactions,
};
