import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';

import { SdmTypography } from './../../../components';
import { Speaker } from './sessionInfo/index';

const MainContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 200px)',
});

const SectionContainer = styled('div')({
  marginTop: 10,
  display: 'flex',
  // width: '50%',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const SessionInfo = ({ i18n, session }) => {
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    if (!session || !session.speakers.length) return;

    const nextSpeakers = session.speakers.map((i) => ({
      ...i,
      start: format(new Date(i.start), 'HH:mm'),
      end: format(new Date(i.end), 'HH:mm'),
      displayName: `${i.givenName} ${i.familyName}`,
    }));

    setSpeakers(nextSpeakers);
  }, [session]);

  return (
    <MainContainer>
      {session?.description && (
        <>
          <SdmTypography variant="sectionTitle" margin={0}>
            {i18n.description.label}
          </SdmTypography>
          <SectionContainer>
            <div dangerouslySetInnerHTML={{ __html: session?.description }} />
          </SectionContainer>
        </>
      )}
      {speakers.length && (
        <SdmTypography variant="sectionTitle" margin={0}>
          {i18n.speakers.label}
        </SdmTypography>
      )}
      <SectionContainer>
        <Grid container spacing={2}>
          {speakers.map((speaker) => (
            <Grid key={speaker.id} item xs={6}>
              <Speaker {...speaker} />
            </Grid>
          ))}
        </Grid>
      </SectionContainer>
    </MainContainer>
  );
};

export { SessionInfo };
