import React from 'react';
import { styled } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';

import { SdmTypography, SdmImage } from './../../../../components';

const SpeakerImage = styled(SdmImage)(() => ({
  objectFit: 'cover',
  width: '100%',
  height: isMobileOnly ? '50vh' : '20vh',
}));

const SpeakerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Description = styled(SdmTypography)({
  color: `rgba(255, 255, 255, 0.8)`,
  textTransform: 'uppercase',
});

const Speaker = ({ displayName, description, image }) => {
  return (
    <SpeakerContainer>
      <SpeakerImage src={image} alt="speaker-image" />
      <div>
        <SdmTypography variant="speakerName" margin={0}>
          {displayName}
        </SdmTypography>
        <Description variant="speakerRole" margin={0}>
          {description}
        </Description>
        {/* <SdmTypography variant="text" margin={0}>
          {`${start} - ${end}`}
        </SdmTypography> */}
      </div>
    </SpeakerContainer>
  );
};

export { Speaker };
