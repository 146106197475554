import aws from './aws';
const API = aws.API;
export const useChatModerator = ({
  accessToken,
  sessionId,
  language,
  channel,
  isMobile,
}) => {
  const apiName = 'sdmVirtualEventModeratorApi';

  const login = async (newClientData) => {
    const input = {
      authKey: createAuthKey(newClientData.uuid, newClientData.msgChannel),
    };
    const res = await API.lambdaPost(apiName, '/login', input);

    return { ...res, ...input };
  };

  const purgeMessage = async (messageTimetoken, msg) => {
    const input = {
      sessionId,
      language,
      accessToken,
      isMobile,
      messageTimetoken,
      channel,
      msg,
    };
    const res = await API.lambdaPost(apiName, '/deleteMessage', input);

    return res;
  };

  const kickUser = async (authKey, uuid) => {
    const input = {
      sessionId,
      uuid,
      language,
      accessToken,
      isMobile,
      authKey,
    };
    const res = await API.lambdaPost(apiName, '/banUser', input);

    return res;
  };
  const restoreUser = async (authKey) => {
    const input = {
      sessionId,
      language,
      accessToken,
      isMobile,
      authKey,
    };
    const res = await API.lambdaPost(apiName, '/unbanUser', input);

    return res;
  };

  const getBlackList = async () => {
    const input = {
      sessionId,
      language,
      accessToken,
      isMobile,
    };
    const res = await API.lambdaPost(apiName, '/getBlackList', input);

    return res?.response;
  };

  const createAuthKey = (uuid, msgChannel) => {
    return `${uuid}-${msgChannel || channel}`;
  };

  return {
    purgeMessage,
    kickUser,
    restoreUser,
    login,
    createAuthKey,
    getBlackList,
  };
};
