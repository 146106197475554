import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { SdmTypography } from './../../components';

import { styled } from '@material-ui/core/styles';
import { theme } from './../../theme';

import { Divider } from '@material-ui/core';

import {
  TabParticipants,
  TabSettings,
  TabSocial,
  TabKicked,
  TabTimeOut,
  CloseTabs,
} from './tabs/index';

import { translation } from './../../utils';

const TabContentContainer = styled('div')({
  color: theme.colors.greyScale.white,
});

const TabTitleContainer = styled('div')({
  color: theme.colors.greyScale.white,
  display: 'flex',
  flexDirection: 'column',
});

const StyledDivider = styled(Divider)({
  backgroundColor: theme.colors.greyScale.grey,
  background: `linear-gradient(to right, ${theme.colors.primary.red} 35%, ${theme.colors.greyScale.grey} 0%)`,
  height: 2,
  marginLeft: 15,
  marginRight: 15,
});

export const Tabs = ({
  publisherData,
  isMicrophoneOpen,
  handleMute,
  isVideoEnabled,
  toggleVideo,
  subscribers,
  isModerator,
  sendSignal,
  kickUser,
  unpublishUser,
  audioInputs,
  videoInputs,
  currentAudioInput,
  currentVideoInput,
  handleInputAudio,
  handleInputVideo,
  isSoundNotificationActive,
  handleSoundNotification,
  msgChannel,
  uuid,
  displayControls,
  fetchDevices,
  selectedTab,
  onClickTabHeader,
  isKicked,
  isTimeOut,
  handsRaised,
  muteAll,
  disableAllScreenShared,
}) => {
  // Hooks
  const intl = useIntl();
  const i18nSocial = translation.breakoutRoom.social(intl);

  const renderTab = () => {
    if (isKicked) {
      return <TabKicked />;
    } else if (isTimeOut) {
      return <TabTimeOut />;
    } else {
      switch (selectedTab?.id) {
        case 1:
          return (
            <TabParticipants
              publisherData={publisherData}
              isMicrophoneOpen={isMicrophoneOpen}
              handleMute={handleMute}
              isVideoEnabled={isVideoEnabled}
              toggleVideo={toggleVideo}
              subscribers={subscribers}
              sendSignal={sendSignal}
              isModerator={isModerator}
              kickUser={kickUser}
              unpublishUser={unpublishUser}
              handsRaised={handsRaised}
              muteAll={muteAll}
              disableAllScreenShared={disableAllScreenShared}
            />
          );
        case 2:
          return (
            <TabSocial
              i18n={i18nSocial}
              displayControls={displayControls}
              msgChannel={msgChannel}
              uuid={uuid}
            />
          );
        case 3:
          return (
            <TabSettings
              audioInputs={audioInputs}
              videoInputs={videoInputs}
              handleInputAudio={handleInputAudio}
              handleInputVideo={handleInputVideo}
              currentAudioInput={currentAudioInput}
              currentVideoInput={currentVideoInput}
              isSoundNotificationActive={isSoundNotificationActive}
              handleSoundNotification={handleSoundNotification}
              displayControls={displayControls}
              fetchDevices={fetchDevices}
            />
          );

        default:
          break;
      }
    }
  };

  const renderMemoCloseTabs = useMemo(
    () => <CloseTabs onClose={() => onClickTabHeader()} />,
    []
  );

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {renderMemoCloseTabs}
        <TabTitleContainer onClick={() => onClickTabHeader()}>
          <SdmTypography style={{ fontSize: 24, marginLeft: 15 }}>
            {selectedTab?.name}
          </SdmTypography>
          <StyledDivider />
        </TabTitleContainer>
        <TabContentContainer>{renderTab()}</TabContentContainer>
      </div>
    </>
  );
};
