import React from 'react';

import { useIntl } from 'react-intl';

import { SdmTypography } from './../../../components';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { translation } from './../../../utils';

import { styled } from '@material-ui/core/styles';

const MainContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 140px)',
  paddingTop: 20,
});

const ContentWrapper = styled('div')({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const TabKicked = () => {
  // Hooks
  const intl = useIntl();
  const i18n = translation.breakoutRoom.tabKicked(intl);

  return (
    <MainContainer>
      <ContentWrapper>
        <InfoOutlinedIcon />
        <SdmTypography
          style={{ font: 'normal normal normal 16px/21px Roboto' }}
        >
          {i18n.page.title.label}
        </SdmTypography>
        <SdmTypography
          textAlign="center"
          style={{ font: 'normal normal normal 16px/21px Roboto' }}
        >
          {i18n.page.message.label}
        </SdmTypography>
      </ContentWrapper>
    </MainContainer>
  );
};

export { TabKicked };
