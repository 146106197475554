import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const EyeIcon = (props) => (
  <SvgIcon
    {...props}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    style={{
      fontSize: 40,
      ...props.style,
    }}
  >
    <g
      id="Ui/Elements/Icons/Eye/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Group"
        transform="translate(8.000000, 13.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <circle id="Oval" cx="12" cy="7.2" r="3.43636364"></circle>
        <path
          d="M3.41983636,10.6702364 C5.43447273,12.3699818 8.55834545,14.3961818 12,14.3961818 C17.8437818,14.3961818 22.8150545,8.63721818 23.9645455,7.19967273 C23.4678,6.57692727 22.2573273,5.14478182 20.5801636,3.72976364 C18.5655273,2.03001818 15.4416545,0.00381818182 12,0.00381818182 C6.15621818,0.00381818182 1.18489091,5.76283636 0.0354545455,7.20032727 C0.5322,7.82301818 1.74267273,9.25521818 3.41983636,10.6702364 Z M12,2.67272727 C14.4963818,2.67272727 16.5272727,4.70361818 16.5272727,7.2 C16.5272727,9.69638182 14.4963818,11.7272727 12,11.7272727 C9.50361818,11.7272727 7.47272727,9.69638182 7.47272727,7.2 C7.47272727,4.70361818 9.50361818,2.67272727 12,2.67272727 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </SvgIcon>
);
