import React from 'react';

import { SdmTypography } from './../../../components';

import { theme } from './../../../theme';
import { styled } from '@material-ui/core/styles';

const StyledTabHeader = styled('div')(({ selected, tabsCount = 1 }) => ({
  color: theme.colors.greyScale.white,
  //paddingRight: 25,
  cursor: 'pointer',
  borderBottom: `2px solid ${
    selected ? theme.colors.primary.red : theme.colors.greyScale.grey
  }`,
  flexBasis: `${100 / tabsCount}%`,
}));

const StyledTypography = styled(SdmTypography)({
  opacity: 1,
  fontWeight: '300 !important',
});

const TabHeader = ({ name, isSelected, onClickTabHeader }) => {
  return (
    <StyledTabHeader selected={isSelected} onClick={onClickTabHeader}>
      <StyledTypography variant="h2" textAlign="center">
        {name}
      </StyledTypography>
    </StyledTabHeader>
  );
};

export { TabHeader };
