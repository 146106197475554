import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const HandBlackFilled = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M19 8C18.31 8 17.75 8.56 17.75 9.25V15H17.25C15.6 15 14.25 16.35 14.25 18H13.25C13.25 15.96 14.78 14.28 16.75 14.03V3.25C16.75 2.56 16.19 2 15.5 2C14.81 2 14.25 2.56 14.25 3.25V11H13.25V1.25C13.25 0.56 12.69 0 12 0C11.31 0 10.75 0.56 10.75 1.25V11H9.75V2.75C9.75 2.06 9.19 1.5 8.5 1.5C7.81 1.5 7.25 2.06 7.25 2.75V12H6.25V5.75C6.25 5.06 5.69 4.5 5 4.5C4.31 4.5 3.75 5.06 3.75 5.75V15.75C3.75 20.31 7.44 24 12 24C16.56 24 20.25 20.31 20.25 15.75V9.25C20.25 8.56 19.69 8 19 8Z"
        fill="black"
      />
    </SvgIcon>
  );
};
