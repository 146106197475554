import React from 'react';

import { styled } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomFormControlLabel = styled(FormControlLabel)({});

// eslint-disable-next-line unused-imports/no-unused-vars
export const SdmFormControlLabel = ({ children, style, ...rest }) => (
  <CustomFormControlLabel {...rest}>{children}</CustomFormControlLabel>
);
