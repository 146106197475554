import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { differenceInSeconds, startOfToday } from 'date-fns';
import { useIntl } from 'react-intl';
import { ImageBackground, CountdownOverlay } from './webinar/index';
import { LoaderContext } from './../contexts';
import { analyticsTrack } from './../utils/analytics';
import { checkLanguage } from './../utils/utility';
import { useAnalyticsTrack } from '../utils/analyticsTrackHelper';
import { aws, useSessions } from './../utils';
const { standardAPI } = aws;

const Webinar = () => {
  const { setLoaderActive } = useContext(LoaderContext);
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);
  const { slugId } = useParams();
  const sessionHelper = useSessions({ showLoader, hideLoader });
  const intl = useIntl();
  const analyticsTrackHelper = useAnalyticsTrack();
  const [start, setStart] = useState();
  const [session, setSession] = useState();
  const [secondRemainingStart, setSecondRemainingStart] = useState();

  useEffect(() => {
    let lang;
    if (location.search) {
      lang = new URLSearchParams(location.search).get('lang');
    }
    if ((intl.locale && !lang) || (intl.locale && lang === intl.locale)) {
      fetchData();
    }
  }, [intl.locale]);

  useEffect(() => {
    if (session?.id) {
      const language = checkLanguage();
      const _analyticsFeatures = {
        pageType: `WEBINAR_${language.toUpperCase()}`,
        language,
        EVENT_LANGUAGE: language?.toUpperCase?.(),
        EVENT_ID: session?.id,
        EVENT_NAME: session?.name,
        EVENT_TYPE: 'Event_Live',
      };
      analyticsTrack(_analyticsFeatures);
      const gqlFeature = {
        ..._analyticsFeatures,
        sessionId: session.id,
        sessionSlug: session.slug,
      };
      createCustomAnalyticsTrack(gqlFeature);
    }
  }, [session]);

  const createCustomAnalyticsTrack = async (input) => {
    try {
      await analyticsTrackHelper.create(input);
    } catch (e) {
      console.error(e);
    }
  };

  const counterTotalTime = differenceInSeconds(start, startOfToday());

  const fetchData = async () => {
    await getAgendaSession();
  };

  const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

  const formatDrupalDate = (ts) => {
    return new Date(safeNum(ts) * 1000);
  };

  const getAgendaSession = async () => {
    try {
      const res = await sessionHelper.get(slugId);
      const sessionId = res.externalId;
      const apiName = 'virtualEventInterface';
      const path = `/external-session/${sessionId}?language=${intl.locale}`;
      const externalSession = await standardAPI.post(apiName, path, {});
      const speakers = Object.values(externalSession?.interventi || {}).flatMap(
        (intervention) => {
          return Object.values(intervention?.speakers || {}).map((speaker) => ({
            id: `${intervention?.id}_${speaker?.id_paragraph}`,
            start: formatDrupalDate(intervention?.start),
            end: formatDrupalDate(intervention?.end),
            description: intervention?.title,
            givenName: speaker?.name,
            familyName: speaker?.surname,
            image: speaker?.image,
          }));
        }
      );
      const nextSession = {
        ...externalSession,
        start: formatDrupalDate(externalSession.start),
        end: formatDrupalDate(externalSession.end),
        speakers,
      };
      setSession(nextSession);
      setStart(new Date(nextSession.start));
      setSecondRemainingStart(
        differenceInSeconds(
          new Date(nextSession.start),
          new Date(nextSession.serverTime)
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <div style={{ height: '100vh', width: '100vw' }}>
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'black',
          }}
        >
          <div
            style={{
              maxHeight: '100vh',
              width: '100vw',
              height: 'calc(100vw / 16 * 9)',
              maxWidth: '177vh',
              overflow: 'hidden',
            }}
          >
            {secondRemainingStart != null && secondRemainingStart <= 0 ? (
              <iframe
                src={session?.urlStreaming}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                width="100%"
                height="100%"
                scrolling="no"
              ></iframe>
            ) : null}
          </div>
        </div>

        {secondRemainingStart != null && secondRemainingStart > 0 ? (
          <ImageBackground
            src={session?.coverImage}
            showShadow
            overlayOpacity={secondRemainingStart > 0 ? 0.55 : 0.8}
          />
        ) : null}
      </div>

      {secondRemainingStart != null && secondRemainingStart > 0 ? (
        <CountdownOverlay
          totalTime={counterTotalTime}
          initialRemainingTime={secondRemainingStart}
          onComplete={() => setSecondRemainingStart(-1)}
        />
      ) : null}
    </div>
  );
};

export { Webinar };
