import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const Chat = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="-10 0 40 20"
    >
      <g
        id="Ui/Elements/Icons/Chat/Black"
        style={{
          stroke: 'none',
          strokeWidth: 1,
          fill: 'none',
          fillRule: 'evenodd',
        }}
      >
        <g
          id="Group"
          style={{
            transform: 'translate(8.000000, 10.000000)',
            fill: '#000000',
            fillRule: 'nonzero',
          }}
        >
          <path
            d="M4.17391304,5.2173913 L19.826087,5.2173913 C20.1391304,5.2173913 20.3478261,5.00869565 20.3478261,4.69565217 C20.3478261,4.3826087 20.1391304,4.17391304 19.826087,4.17391304 L4.17391304,4.17391304 C3.86086957,4.17391304 3.65217391,4.3826087 3.65217391,4.69565217 C3.65217391,5.00869565 3.86086957,5.2173913 4.17391304,5.2173913 Z"
            id="Path"
          ></path>
          <path
            d="M4.17391304,8.34782609 L19.826087,8.34782609 C20.1391304,8.34782609 20.3478261,8.13913043 20.3478261,7.82608696 C20.3478261,7.51304348 20.1391304,7.30434783 19.826087,7.30434783 L4.17391304,7.30434783 C3.86086957,7.30434783 3.65217391,7.51304348 3.65217391,7.82608696 C3.65217391,8.13913043 3.86086957,8.34782609 4.17391304,8.34782609 Z"
            id="Path"
          ></path>
          <path
            d="M4.17391304,11.4782609 L19.826087,11.4782609 C20.1391304,11.4782609 20.3478261,11.2695652 20.3478261,10.9565217 C20.3478261,10.6434783 20.1391304,10.4347826 19.826087,10.4347826 L4.17391304,10.4347826 C3.86086957,10.4347826 3.65217391,10.6434783 3.65217391,10.9565217 C3.65217391,11.2695652 3.86086957,11.4782609 4.17391304,11.4782609 Z"
            id="Path"
          ></path>
          <path
            d="M0,2.60869565 C0,1.14782609 1.14782609,0 2.60869565,0 L2.60869565,0 L21.3913043,0 C22.8521739,0 24,1.14782609 24,2.60869565 L24,2.60869565 L24,13.0434783 C24,14.5043478 22.8521739,15.6521739 21.3913043,15.6521739 L21.3913043,15.6521739 L9.07826087,15.6521739 L5.06086957,19.6695652 C4.95652174,19.773913 4.85217391,19.826087 4.69565217,19.826087 C4.64347826,19.826087 4.53913043,19.826087 4.48695652,19.773913 C4.27826087,19.7217391 4.17391304,19.5130435 4.17391304,19.3043478 L4.17391304,19.3043478 L4.17391304,15.6521739 L2.60869565,15.6521739 C1.14782609,15.6521739 0,14.5043478 0,13.0434783 L0,13.0434783 Z M1.04347826,13.0434783 C1.04347826,13.9304348 1.72173913,14.6086957 2.60869565,14.6086957 L2.60869565,14.6086957 L4.69565217,14.6086957 C5.00869565,14.6086957 5.2173913,14.8173913 5.2173913,15.1304348 L5.2173913,15.1304348 L5.2173913,18.0521739 L8.50434783,14.7652174 C8.60869565,14.6608696 8.71304348,14.6086957 8.86956522,14.6086957 L8.86956522,14.6086957 L21.3913043,14.6086957 C22.2782609,14.6086957 22.9565217,13.9304348 22.9565217,13.0434783 L22.9565217,13.0434783 L22.9565217,2.60869565 C22.9565217,1.72173913 22.2782609,1.04347826 21.3913043,1.04347826 L21.3913043,1.04347826 L2.60869565,1.04347826 C1.72173913,1.04347826 1.04347826,1.72173913 1.04347826,2.60869565 L1.04347826,2.60869565 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};
