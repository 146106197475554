import React, { useState, useEffect, useContext } from 'react';

import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { differenceInSeconds } from 'date-fns';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import { styled } from '@material-ui/core/styles';
import { Drawer, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { translation, useBreakoutRoom, playJoinNotification } from './../utils';
import { analyticsTrack } from './../utils/analytics';
import { checkLanguage } from './../utils/utility';
import { useAnalyticsTrack } from '../utils/analyticsTrackHelper';

import { theme } from './../theme';

import {
  useTabs,
  BottomControls,
  TopControls,
  SdmCustomSlider,
  Tabs,
  PublisherDraggableControls,
} from './breakoutroom/index';

import {
  SdmTypography,
  SdmIconAndTextButton,
  SdmDialog,
} from './../components';

import { AuthContext, LoaderContext } from './../contexts';

const StyledDrawer = styled(Drawer)(({ isMobile }) => ({
  '& .MuiPaper-root': {
    borderLeft: 'none',
    marginBottom: 55,
    zIndex: isMobile ? 9999 : 'auto',
  },
}));

const getSecondsFromServerTime = (date, serverTime) => {
  return differenceInSeconds(date, serverTime);
};

const REACT_APP_BR_BACK_REDIRECT = process.env.REACT_APP_BR_BACK_REDIRECT;

const BreakoutroomPage = () => {
  // Loader Functions
  const showLoader = (text) => setLoaderActive(text ? { text } : true);
  const hideLoader = () => setLoaderActive(false);

  // Hook
  const MUITheme = useTheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down('sm'));
  const analyticsTrackHelper = useAnalyticsTrack();

  //Constants
  const subscribersDivName = 'subscribers';
  const screenSharingDivName = 'screens';
  const publisherDivName = 'publisher';
  const carouselDivName = 'carousel';
  const rightColumnWidth = isMobile ? '100vw' : 470;
  const bottomControlHeight = 55;
  const topControlHeight = 55;

  //Contexts
  const { user } = useContext(AuthContext);
  const { setLoaderActive } = useContext(LoaderContext);

  // Intl Hooks
  const intl = useIntl();
  const i18n = translation.breakoutRoom.breakoutRoomPage(intl);
  const tabs = useTabs(i18n.tabHeaders);

  //State
  const [publisherData, setPublisherData] = useState();
  const [isModerator, setIsModerator] = useState(false);
  const [isSoundNotificationActive, setSoundNotificationActive] = useState(
    localStorage.getItem('sdmBRPlayNotification') === 'true' || false
  );
  const [client, setClient] = useState();
  const [clientData, setClientData] = useState({
    uuid: null,
    msgChannel: null,
  });
  const [dialog, setDialog] = useState({ open: false });
  const [displayControls, setDisplayControls] = useState(true);
  const [selectedLayout, setSelectedLayout] = useState(1);
  const [selectedTab, setSelectedTab] = useState(null);
  const [layoutListChildStyle, setLayoutListChildStyle] = useState({
    'min-width': { 1: selectedTab ? '24%' : '16%', 2: '0px' },
    'max-width': { 1: selectedTab ? '24%' : '16%', 2: 'none' },
    'margin-right': { 1: '10px', 2: '0px' },
  });
  const [analyticsFeature, setAnalyticsFeature] = useState();

  // Hooks
  const { breakoutroomId } = useParams();
  const {
    session,
    info,
    serverTime,
    isMicrophoneOpen,
    audioInputs,
    videoInputs,
    isScreenShared,
    publisherScreen,
    shareScreenFunction,
    handleMute,
    toggleVideo,
    handleInputAudio,
    handleInputVideo,
    isVideoEnabled,
    publisher,
    subscribers,
    sendSignal,
    sendBroadcastSignal,
    hideSubscribersOutsideViewport,
    fetchDevices,
    currentAudioInput,
    currentVideoInput,
    screenSharedData,
    handsRaised,
  } = useBreakoutRoom({
    breakoutroomId,
    subscribersDivName,
    screenSharingDivName,
    publisherDivName,
    carouselDivName,
    handleError: (error, showError) => handleErrorDialog(error, showError),
    onParticipantJoin: () => handleParticipantJoin(),
    onKick: () => {
      setDialog({
        open: true,
        data: {
          message: i18n.dialog.kicked.message.label,
          title: i18n.dialog.kicked.title.label,
          kick: true,
          timeExpired: false,
        },
      });
      setDisplayControls(false);
    },
    onUnpublish: () => {
      setDialog({
        open: true,
        data: {
          message: i18n.dialog.unpublished.message.label,
          title: i18n.dialog.unpublished.title.label,
          kick: false,
          timeExpired: false,
        },
      });
      setDisplayControls(false);
    },
    selectedLayout,
    layoutListChildStyle,
    showLoader,
    hideLoader,
    user,
  });

  //Effects
  useEffect(() => {
    setLayoutListChildStyle({
      'min-width': { 1: selectedTab ? '24%' : '16%', 2: '0px' },
      'max-width': { 1: selectedTab ? '24%' : '16%', 2: 'none' },
      'margin-right': { 1: '10px', 2: '0px' },
    });
  }, [selectedTab]);

  useEffect(() => {
    if (publisher) {
      const stringData = publisher?.session?.connection?.data;
      const nextPublisherData = JSON.parse(stringData);
      const nextIsModerator =
        publisher?.session?.capabilities?.permittedTo('forceDisconnect');
      setIsModerator(nextIsModerator);
      setPublisherData(nextPublisherData);
    }
  }, [publisher]);

  useEffect(() => {
    if (user && breakoutroomId) {
      if (analyticsFeature) {
        const gqlFeature = {
          ...analyticsFeature,
          sessionId: breakoutroomId,
        };
        createCustomAnalyticsTrack(gqlFeature);
      }
      if (!client) {
        initChatClient();
      }
    }
  }, [user, breakoutroomId, analyticsFeature]);

  const onTimeExpired = () => {
    setDialog({
      open: true,
      data: {
        message: i18n.dialog.timeExpired.message.label,
        title: i18n.dialog.timeExpired.title.label,
        kick: true,
        timeExpired: true,
      },
    });
    setDisplayControls(false);
  };

  useEffect(() => {
    if (info?.end && serverTime && onTimeExpired) {
      let secondsToEnd = getSecondsFromServerTime(
        new Date(info?.end),
        new Date(serverTime)
      );
      secondsToEnd =
        secondsToEnd > 2147483647 / 1000 ? 2147483647 / 1000 : secondsToEnd;

      const timeouts = [];

      if (secondsToEnd - 60 * 5 > 0) {
        // 5 minute before the breakout room end
        const timeout10MinuteBefore = setTimeout(() => {
          /* showSnackbar({
            severity: AimSnackbarSeverity.error,
            message: i18n.dialog.timeExpired.snackbar.minutesLeftInfo.label,
            style: {
              backgroundColor: '#C7F2F2',
              color: '#30CFD0',
              display: 'flex',
              alignItems: 'center',
            },
            autoHideDuration: 5 * 1000,
            actions: () => <></>,
            onClose: () => {
              setSnackbar({
                isOpen: false,
                actions: null,
                style: null,
                autoHideDuration: null,
                onClose: () => {},
              });
            },
          }); */
        }, (secondsToEnd - 60 * 5) * 1000);
        timeouts.push(timeout10MinuteBefore);
      }
      if (secondsToEnd > 0) {
        // the end of the breakout room time
        const timeoutEnd = setTimeout(() => {
          onTimeExpired();
        }, secondsToEnd * 1000);
        timeouts.push(timeoutEnd);
      }
      /* if (secondsToEnd + 60 * 10 > 0) {
        // time after the end of breakout room
        const timeout10MinuteAfter = setTimeout(() => {
          onTimeExpired();
        }, (secondsToEnd + 60 * 10) * 1000);
        timeouts.push(timeout10MinuteAfter);
      } */
      return () => {
        for (const timeout of timeouts) {
          clearTimeout(timeout);
        }
      };
    }
  }, [info, serverTime, onTimeExpired]);

  useEffect(() => {
    if (user && info && breakoutroomId) {
      const userId = user.id;
      const language = checkLanguage();

      const _analyticsFeatures = {
        pageType: 'BREAKOUTROOM',
        language,
        userId,
        loginStatus: 'logged',
        EVENT_LANGUAGE: language?.toUpperCase?.(),
        EVENT_ID: breakoutroomId,
        EVENT_NAME: `${info?.name || ''}${
          info?.brand ? ` - ${info.brand}` : ''
        }`,
        EVENT_TYPE: 'Event_Breakout-Room',
      };

      setAnalyticsFeature(_analyticsFeatures);
      analyticsTrack(_analyticsFeatures);
    }
  }, [user, info]);

  const createCustomAnalyticsTrack = async (input) => {
    try {
      await analyticsTrackHelper.create(input);
    } catch (e) {
      console.error(e);
    }
  };

  //Functions
  const initChatClient = async () => {
    if (clientData.uuid || client) return;

    const newClientData = {
      uuid: user?.id,
      msgChannel: breakoutroomId,
    };
    setClientData(newClientData);

    setClient(
      new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        uuid: newClientData.uuid,
      })
    );
  };

  const kickUser = (session) => {
    publisher.session.forceDisconnect(session.stream.connection);
  };

  const unpublishUser = (session) => {
    publisher.session.forceUnpublish(session.stream);
  };

  const setAudioInput = (value) => {
    handleInputAudio(value);
  };

  const setVideoInput = (value) => {
    handleInputVideo(value);
  };

  const handleSoundNotification = (value) => {
    setSoundNotificationActive(value);
    localStorage.setItem('sdmBRPlayNotification', value);
  };

  const handleParticipantJoin = () => {
    if (localStorage.getItem('sdmBRPlayNotification') === 'true')
      playJoinNotification();
  };

  const navigateBack = () => {
    showLoader(i18n.loader.disconnect.label);
    try {
      setDisplayControls(false);
      session.off();
      session.disconnect();
    } catch (e) {
      // do nothing
    }

    try {
      window.location.href = REACT_APP_BR_BACK_REDIRECT;
    } catch (e) {
      hideLoader();
    }
  };

  const onClickTabHeader = (tab) => {
    setSelectedTab(tab);
  };

  const getSubscriberDivStyle = () => {
    const defaultObject = {
      display: isScreenShared ? 'none' : 'flex',
      flex: 1,
      maxWidth: '100%',
      maxHeight: `calc(${selectedLayout === 2 ? '(100% / 2) ' : '80%'} )`,
      flexWrap: 'wrap',
      justifyContent: 'center',
    };

    switch (subscribers.length) {
      case 1:
      case 2:
        return { ...defaultObject, maxHeight: '100%' };
      default:
        return defaultObject;
    }
  };

  const handleErrorDialog = (error, openDialog) => {
    console.error(error);
    if (error && openDialog) {
      const data = {
        message: i18n.dialog.error.message.label,
        title: i18n.dialog.error.title.label,
        kick: false,
        timeExpired: false,
      };
      setDialog({ open: true, data });
    }
  };

  const handleDialogClose = (dialog) => {
    if (dialog?.data?.kick) {
      navigateBack();
    } else {
      setDialog({ open: false });
    }
  };

  const raiseHand = () => {
    const isHandRaised = handsRaised.find((hr) => hr === publisherData.id);
    sendBroadcastSignal(
      `${isHandRaised ? 'unraise-hand' : 'raise-hand'}|${publisherData.id}`
    );
  };

  const muteAll = () => {
    sendBroadcastSignal(`force-mute-all`);
  };

  const disableAllScreenShared = () => {
    sendBroadcastSignal(`force-disable-screen-sharing-all`);
  };

  return (
    <>
      <TopControls
        title={`${info?.name || ''}${info?.brand ? ` - ${info.brand}` : ''}`}
        navigateBack={navigateBack}
        topControlHeight={topControlHeight}
      />
      <div
        style={{
          display: 'flex',
          backgroundColor: '#36393F',
          height: `calc(100vh - ${isMobile ? 150 : 55}px)`,
        }}
      >
        <div
          style={{
            width: `calc(100vw - ${
              selectedTab ? rightColumnWidth + 'px' : '0px'
            })`,
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent:
                selectedLayout === 2 ? 'flex-start' : 'space-between',
            }}
          >
            <div id={`${subscribersDivName}`} style={getSubscriberDivStyle()} />

            <div
              id={`${screenSharingDivName}`}
              style={{
                display: isScreenShared ? 'flex' : 'none',
                flex: 1,
                maxWidth: '100%',
                maxHeight: '100%',
                flexWrap: 'wrap',
              }}
            />

            <div
              style={{
                display:
                  selectedLayout === 2 && !isScreenShared ? 'none' : 'flex',
                height: '20%',
                position: 'relative',
                backgroundColor: '#04000073',
              }}
            >
              <SdmCustomSlider
                carouselDivName={carouselDivName}
                onNavigate={hideSubscribersOutsideViewport}
              />
            </div>

            {isScreenShared && screenSharedData ? (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'black',
                  cursor: 'context-menu',
                  opacity: 0.6,
                  left: 10,
                  bottom: 'calc(20% + 55px)',
                }}
              >
                <SdmTypography style={{ color: 'white' }}>
                  {`${screenSharedData?.FirstName} ${screenSharedData?.LastName} `}
                </SdmTypography>
              </div>
            ) : null}
          </div>
        </div>
        <StyledDrawer
          variant="persistent"
          anchor="right"
          open={selectedTab != null}
          isMobile={isMobile}
        >
          <div
            style={{
              width: rightColumnWidth,
              backgroundColor: theme.colors.greyScale.backgroundGrey,
              height: `100%`,
            }}
          >
            {client && clientData?.msgChannel && clientData?.uuid && (
              <PubNubProvider client={client}>
                <Tabs
                  publisherData={publisherData}
                  isMicrophoneOpen={isMicrophoneOpen}
                  handleMute={handleMute}
                  isVideoEnabled={isVideoEnabled}
                  toggleVideo={toggleVideo}
                  subscribers={subscribers}
                  isModerator={isModerator}
                  sendSignal={sendSignal}
                  kickUser={kickUser}
                  unpublishUser={unpublishUser}
                  audioInputs={audioInputs}
                  videoInputs={videoInputs}
                  currentAudioInput={currentAudioInput}
                  currentVideoInput={currentVideoInput}
                  handleInputAudio={setAudioInput}
                  handleInputVideo={setVideoInput}
                  isSoundNotificationActive={isSoundNotificationActive}
                  handleSoundNotification={handleSoundNotification}
                  msgChannel={clientData?.msgChannel}
                  uuid={clientData?.uuid}
                  user={user}
                  session={breakoutroomId}
                  displayControls={displayControls}
                  fetchDevices={fetchDevices}
                  selectedTab={selectedTab}
                  onClickTabHeader={onClickTabHeader}
                  isKicked={dialog?.data?.kick && !dialog?.data?.timeExpired}
                  isTimeOut={dialog?.data?.kick && dialog?.data?.timeExpired}
                  handsRaised={handsRaised}
                  muteAll={muteAll}
                  disableAllScreenShared={disableAllScreenShared}
                />
              </PubNubProvider>
            )}
          </div>
        </StyledDrawer>
      </div>
      {displayControls ? (
        <PublisherDraggableControls
          {...{
            publisher,
            publisherDivName,
            publisherScreen,
            isMicrophoneOpen,
          }}
        />
      ) : null}
      <SdmDialog
        open={dialog?.open}
        title={dialog?.data?.title}
        onClose={() => {
          handleDialogClose(dialog);
        }}
        hideActions
        customActions={
          <div style={{ width: '100%' }}>
            <SdmIconAndTextButton
              variant="greenFill"
              text={i18n.dialog.buttons.ok.label}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
              }}
              onClick={() => {
                handleDialogClose(dialog);
              }}
            />
          </div>
        }
      >
        <SdmTypography>{dialog?.data?.message}</SdmTypography>
      </SdmDialog>
      <BottomControls
        {...{
          navigateBack,
          selectedLayout,
          setSelectedLayout,
          onClickTabHeader,
          tabs,
          selectedTab,
          bottomControlHeight,
          handleMute,
          isMicrophoneOpen,
          toggleVideo,
          isVideoEnabled,
          isScreenShared,
          shareScreenFunction,
          publisherScreen,
          raiseHand,
        }}
        isHandRaised={handsRaised.find((h) => h === publisherData?.id)}
      />
    </>
  );
};

const Breakoutroom = React.memo(BreakoutroomPage);

export { Breakoutroom };
