import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import { theme } from '../theme';
import { styled } from '@material-ui/core/styles';

const CustomFormControl = styled(FormControl)({
  margin: theme.spacing(1),
  minWidth: 120,
  flex: 1,
});

const CustomTextField = styled(TextField)(({ textfieldvariant }) => ({
  ...textfieldvariant,
  height: 40,
}));

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
});

const formControlLabelPlacementTop = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variants = {
  grey: {
    backgroundColor: '#f9f5fa',
  },
  white: {
    backgroundColor: 'white',
  },
};

export const SdmTextField = ({
  label,
  displayLabel = true,
  formControlStyle,
  textfieldVariant = 'grey',
  labelPlacementLeft,
  InputProps,
  inputPropsStyle,
  ...rest
}) => {
  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };

  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};

  return (
    <CustomFormControl style={controlStyle}>
      {displayLabel ? (
        <CustomFormLabel style={labelStyle}>{label}</CustomFormLabel>
      ) : (
        <></>
      )}
      <CustomTextField
        {...rest}
        textfieldvariant={variants[textfieldVariant]}
        variant="standard"
        InputProps={{
          ...InputProps,
          disableUnderline: true,
        }}
        inputProps={{
          style: {
            marginTop: 8,
            marginBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
            ...inputPropsStyle,
          },
        }}
      />
    </CustomFormControl>
  );
};
