import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';

import { theme } from '../theme';
import { styled } from '@material-ui/core/styles';

const StyledCheckbox = styled(Checkbox)({
  height: '18px !important',
  width: '18px !important',
  '&.MuiCheckbox-colorSecondary.Mui-checked': {
    color: theme.colors.primary.red,
  },
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
});
const StyledCheckboxOutline = styled(CheckBoxOutlineBlankIcon)(
  ({ variant }) => ({
    ...variants[variant],
    width: 18,
    height: 18,
    margin: 3,
  })
);
const StyledCheckedIcon = styled(CheckIcon)({
  background: theme.colors.primary.red + '!important',
  color: 'white !important',
  width: 18,
  height: 18,
  margin: 3,
});

const StyledRemoveIcon = styled(RemoveIcon)({
  background: theme.colors.primary.red + '!important',
  color: 'white !important',
  width: 18,
  height: 18,
  margin: 3,
});

const CustomFormControlLabel = styled(FormControlLabel)({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
});

const variants = {
  grey: {
    background: theme.colors.greyScale.grey + ' !important',
    color: theme.colors.greyScale.grey + ' !important',
  },
  white: {
    background: `${theme.colors.greyScale.white} !important`,
    color: `${theme.colors.greyScale.white} !important`,
  },
};

export const SdmCheckbox = ({
  variant = 'grey',
  label,
  labelPlacementLeft,
  ...rest
}) => {
  if (!label) {
    return (
      <StyledCheckbox
        icon={<StyledCheckboxOutline variant={variant} />}
        checkedIcon={<StyledCheckedIcon />}
        indeterminateIcon={<StyledRemoveIcon />}
        {...rest}
      />
    );
  }

  return (
    <CustomFormControlLabel
      control={
        <StyledCheckbox
          icon={<StyledCheckboxOutline variant={variant} />}
          checkedIcon={<StyledCheckedIcon />}
          indeterminateIcon={<StyledRemoveIcon />}
          {...rest}
          disableRipple
        />
      }
      label={label}
      labelPlacement={labelPlacementLeft ? 'start' : 'top'}
    />
  );
};
