import React from 'react';

import { styled } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { theme } from './../theme';

// eslint-disable-next-line unused-imports/no-unused-vars
const CustomFormControl = styled(({ formControlWidth, ...other }) => (
  <FormControl {...other} />
))(({ style }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
  flex: 1,
  width: (props) => props.formControlWidth,
  ...style,
}));

const CustomRadio = styled(Radio)({
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
});

const CustomIcon = styled('span')({
  borderRadius: '50%',
  width: 24,
  height: 24,
  backgroundColor: '#F8F6FA',
});

const CustomCheckedIcon = styled(CustomIcon)({
  backgroundColor: '#F8F6FA',
  backgroundImage: 'linear-gradient(180deg, #84819A, 84819A)',
  '&:before': {
    display: 'block',
    width: 11,
    height: 11,
    borderRadius: '50%',
    backgroundColor: '#84819A',
    marginLeft: '50%',
    marginTop: '50%',
    transform: 'translate(-50%, -50%)',
    content: '""',
  },
});

const StyledRadio = (props) => (
  <CustomRadio
    disableRipple
    color="default"
    checkedIcon={<CustomCheckedIcon />}
    icon={<CustomIcon />}
    {...props}
  />
);

export const SdmRadio = ({
  label,
  formControlWidth,
  children,
  formControlStyle,
  ...rest
}) => (
  <CustomFormControl
    formControlWidth={formControlWidth}
    style={formControlStyle}
  >
    {label && (
      <FormLabel
        style={{
          marginBottom: 5,
          marginTop: 0,
          paddingBottom: 0,
          color: 'black',
          fontWeight: 'bold',
          fontSize: '14px',
        }}
      >
        {label}
      </FormLabel>
    )}
    <RadioGroup row {...rest}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ...child.props,
          control: <StyledRadio />,
        });
      })}
    </RadioGroup>
  </CustomFormControl>
);
